import React, { PropsWithChildren, useState } from 'react';

import { Button, Flex, Modal, Tooltip, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type Props = {
    delete: () => void,
    deleteText: string
    disabled?: boolean,
    title?: string
}

function DeleteConfirm(props: PropsWithChildren<Props>) {
    const {t} = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const disabled = props.disabled || false;

    const ModalTitle = () => {
        return (
            <Flex style={{ flexDirection: 'row' }} gap={10}>
                <ExclamationCircleFilled style={{ color: 'rgb(253, 179, 0)', fontSize: '22px' }}/>
                <Typography.Text style={{ fontSize: '16px' }}>{props.deleteText}</Typography.Text>
            </Flex>
        );
    }

    return (
    <>
        <Tooltip
            title={props.title}
        >
            <Button
                onClick={() => setOpen(true)}
                disabled={disabled}
            >
                {props.children}
            </Button>
        </Tooltip>
        <Modal
            closeIcon={null}
            open={open}
            height={138}
            width={416}
            title={<ModalTitle/>}
            okText={t('form.remove')}
            okType='danger'
            cancelText={t('form.cancel')}
            onCancel={() => setOpen(false)}
            onOk={() => {
                props.delete()
                setOpen(false)
            }}
            destroyOnClose
        >
            <br/>
        </Modal>
    </>
  );
}

export default DeleteConfirm;
