import { Flex, Input, Modal, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface AddingModalProps {
    title: string;
    open: boolean;
    onCancel: () => void;
    onOk: (value: string) => void;
    description: string;
    placeholder: string;
}

function AddingModal(props: AddingModalProps) {
    const [value, setValue] = useState<string>('');
    const { t } = useTranslation();

    return (
        <Modal
            title={props.title}
            open={props.open}
            onCancel={props.onCancel}
            onOk={() => props.onOk(value)}
            cancelText={t("form.cancel")}
            okText={t("form.add")}
            okButtonProps={{disabled: value.length === 0}}
        >
            <Flex vertical>
                <Typography.Text>{props.description}</Typography.Text>
                <Input placeholder={props.placeholder} onChange={(e) => setValue(e.target.value)}/>
            </Flex>
        </Modal>
    )
}

export default AddingModal;