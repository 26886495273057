import React, { useEffect, useState } from "react"
import { Card, Divider, Drawer, Flex, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { TFunction } from "i18next";

import './calendarDrawer.css'
import { EventDrawerAppointment } from "./AppointmentDrawer";
import { EventDrawerTreatment } from "./TreatmentDrawer";
import { customTheme } from "../../../config/theme";
import { useTranslation } from "react-i18next";
import PetAvatar from "../../pets/PetAvatar";
import { getTranslatedEventType } from "../CalendarDisplay";

export function displayDate(date: string, t: TFunction<"translation", undefined>) {
    const months = [
        t("month.january"), t("month.february"), t("month.march"), t("month.april"), t("month.may"), t("month.june"),
        t("month.july"), t("month.august"), t("month.september"), t("month.october"), t("month.november"), t("month.december")
    ];
    const [year, month, day] = date.split("-")

    return `${day} ${months[Number(month) - 1]} ${year}`
}

function EventDrawerGridView(props: any) {
    const {t} = useTranslation();

    const [open, setOpen] = useState<boolean>(true);

    const [drawerEvent, setDrawerEvent] = useState<{event: any}>();
    const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const onDrawerClose = (noUse: boolean) => {
        setTimeout(() => {
          setOpenStateDrawer(false)
        }, 250);
    }

    return (
        <>
            <div>
                {openStateDrawer &&
                    <>
                        {drawerEvent && (drawerEvent as any)?.type === 'A' ? (
                            <EventDrawerAppointment onClose={onDrawerClose} event={drawerEvent} renew={props.renew}/>
                        ) : (
                            <EventDrawerTreatment onClose={onDrawerClose} event={drawerEvent} renew={props.renew}/>
                        )}
                    </>
                }
            </div>
            <Drawer
                title={`${displayDate(props.events.month, t)}`}
                onClose={handleClose}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
                className={`drawer-grid-view ${openStateDrawer ? "active" : ''}`}
            >
                <>
                    {props.events.events.map((event: any, key: any) => (
                        <>
                            <div style={{ marginBottom: '10px' }}>
                                <Card
                                    key={key}
                                    style={{
                                        height: '50px', width: 'auto', maxWidth: '600px',minWidth: '100px',
                                        cursor: 'pointer',
                                        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                                    }}
                                    onClick={() => {
                                        setDrawerEvent(event);
                                        setOpenStateDrawer(true)
                                    }}
                                    hoverable
                                >
                                    <Flex
                                                style={{ alignItems: 'center', cursor: 'pointer', width: '100%' }}
                                                gap={10}
                                    >
                                        <PetAvatar species={event.pet.species} url={event.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                                        {' '}
                                        <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                                        <Typography.Text style={{ fontSize: '17px' }}>{(event.date).split(" ")[0]} - {getTranslatedEventType(event.name, t)}</Typography.Text>

                                    </Flex>
                                </Card>
                            </div>
                        </>
                    ))}
                </>
            </Drawer>
        </>
    );
}

export { EventDrawerGridView }