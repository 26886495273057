import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Flex, FloatButton, Typography } from 'antd';
import _ from 'lodash';

import './home.css'
import ChangeLang from '../../component/lang/ChangeLang';
import { customTheme } from '../../config/theme';
import { ReactComponent as HorizontalFr } from "../../assets/images/landingPage/communityPath/horizontal/fr.svg";
import { ReactComponent as HorizontalEn } from "../../assets/images/landingPage/communityPath/horizontal/en.svg";
import { ReactComponent as VerticalFr } from "../../assets/images/landingPage/communityPath/vertical/fr.svg";
import { ReactComponent as VerticalEn } from "../../assets/images/landingPage/communityPath/vertical/en.svg";
import { ReactComponent as ScreenshotFr } from "../../assets/images/landingPage/Screenshot_fr.svg";


function Home() {
  const {t} = useTranslation();

  const [langage, setLangage] = useState<string>("en");
  const [isVertical, setIsVertical] = useState(false);


  const horizontalCommunityPath: {[x: string]: JSX.Element} = {
    "fr": <HorizontalFr/>,
    "en": <HorizontalEn/>
  }

  const verticalCommunityPath: {[x: string]: JSX.Element} = {
    "fr": <VerticalFr/>,
    "en": <VerticalEn/>
  }

  useEffect(() => {
    setLangage(document.documentElement.lang);
  }, [localStorage.getItem('savedLanguage')])

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 650) {
        setIsVertical(true)
      } else {
        setIsVertical(false)
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div style={{ color: 'white' }}>
      <Flex style={{ flexDirection: 'column', width: '100%' }} className='header'>
        <Flex className='header-nav'>{' '}</Flex>
        <Flex justify='space-around' style={{ position: 'fixed' }} className='header-nav'>
          <Flex justify='center' style={{ flexDirection: 'column' }}>
            <Link to='/'>
              <img
                className='header-logo'
                src="assets/images/landingPage/AppLogo.png"
                width={89}
                alt=""
              />
            </Link>
          </Flex>
          <Flex style={{ flexDirection: 'row' }} gap={30} className='header-nav-input'>
            <Flex justify='center' style={{ flexDirection: 'column' }}>
              <Link to='/application' className='header-app-access'>
                  {t("home.access-application")}
              </Link>
            </Flex>
            <ChangeLang dropDownMode/>
          </Flex>
        </Flex>
        <Flex justify='center' style={{ flexDirection: 'row', alignItems: 'center' }} className='header-content'>
          <Flex align="center" justify='center' className='header-content-flex-direction' gap={10}>
            <Typography.Text style={{ textAlign: "right", color: 'white' }} className='header-content-text'>{t("home.slogan")}</Typography.Text>
            <Flex style={{ width: '100%', maxWidth: '350px', height: '100%', maxHeight: '472px' }}>
              <ScreenshotFr/>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex style={{ flexDirection: 'column', width: '100%', backgroundColor: 'white' }} gap={20} className='mission'>
        <Flex gap={15} className='mission-content'>
          <Typography.Text className='mission-content-title'>{t("home.our-goal")}</Typography.Text>
          <Typography.Text className='mission-content-text'>{t("home.goal-description")}</Typography.Text>
        </Flex>
        <Flex justify='center' style={{ width: '100%', flexDirection: 'row', marginBottom: 30 }} gap={1}>
          <Flex style={{ width: '40%' }}>
            <video width="100%" autoPlay loop playsInline>
              <source src="assets/videos/Mission.mp4" type="video/mp4"/>
            </video>
          </Flex>
          <Flex style={{ width: '40%' }}>
            <img
              src="assets/images/landingPage/Mission.png"
              alt=""
              style={{ width: '100%', maxWidth: '768px', height: '100%', maxHeight: '768px', objectFit: 'cover', objectPosition: 'center 90%' }}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex style={{ width: '100%', height: '100%', maxHeight: '450px', position: 'absolute', zIndex: -1 }} className='join-us-bg'>
        {' '}
      </Flex>
      <Flex style={{ flexDirection: 'column', width: '100%', minHeight: '800px', alignItems: 'center' }} className='join-us'>
        <Flex style={{ height: '160px', alignItems: 'center', marginBottom: '40px' }}>
          <Typography.Text style={{ color: 'white', textAlign: 'center' }} className='title'>{t('home.join-us')}</Typography.Text>
        </Flex>
        <Flex justify='space-around' style={{ flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 50 }} gap={40} className='images'>
          {_.times(3, (key: number) => (
            <Flex
              style={{ width: '100%', height: '100%',  flexDirection: 'column', alignItems: 'center' }}
              gap={20}
              className='image'
              key={key}
            >
              <img
                src={`assets/images/landingPage/joinUs/img_${key + 1}.jpg`}
                loading="lazy"
                style={{ width: 'calc(100% - 20px)', maxWidth: '500px', height: '100%', maxHeight: '330px', borderRadius: '10px' }}
              />
              <Flex justify='center' style={{ width: '70%', height: '50px' }}>
                <Typography.Text className='text'>{t(`home.join-us-text.${key + 1}`)}</Typography.Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex style={{ flexDirection: 'column', width: '100%', minHeight: '420px' }} className='how-it-works'>
        <Flex justify='center' style={{ flexDirection: 'column', width: '100%', height: "85px" }} className='title'>
          <Typography.Text style={{ color: 'white' }} className='text'>{t('home.how-it-works')}</Typography.Text>
        </Flex>
        <Flex style={{ width: isVertical ? '100%' : 'calc(100% - 100px)', alignSelf: 'center', margin: isVertical ? '20px' : '50px' }}>
          {isVertical
            ? verticalCommunityPath[langage]
            : horizontalCommunityPath[langage]
          }
        </Flex>
      </Flex>
      <Flex justify='center' style={{ width: '100%', height: '100dvh', background: customTheme.gradientBackground, alignItems: 'center' }} className='lets-go'>
        <Typography.Text style={{ color: 'white', fontWeight: 'bold' }} className='text'>{t('home.lets-go')}</Typography.Text>
      </Flex>
      <FloatButton.BackTop/>
    </div>
  );
}

export default Home;