import { useEffect, useState } from "react";
import { Badge, Button, Card, ConfigProvider, Divider, Flex, Modal, Typography, message } from "antd";
import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";
import PetAvatar from "../../pets/PetAvatar";
import { customTheme } from "../../../config/theme";
import { displayMonthYear, getTranslatedEventType } from "../CalendarDisplay";
import CalendarModal from "./CalendarModal";
import { linkTreatment } from "../../../request/events";
import { formatDateToIso } from "../comingEvents/ComingEvents";

function LinkModal(props: any) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [selectedAppointment, setSelectedAppointment] = useState("");
    const [veterinaryEvents, setVeterinaryEvents] = useState({});

    const [isModalVisible, setModalVisibility] = useState(false);

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const handleAppointmentSubmit = async (submit: boolean) => {
        setModalVisibility(false);
        if (submit) {
            props.renew()
        }
    }

    async function handleSubmit() {
        try {
            await linkTreatment(await getAccessToken(), props.event._id, selectedAppointment);
            message.success(t("treatment-link.success"));
        } catch(err: any) {
            setSelectedAppointment("")
            props.onFinish(false, "");
            message.error(t("treatment-link.error"));
            return false;
        }
        setSelectedAppointment("")
        props.onFinish(true, selectedAppointment);
    }


    useEffect(() => {
        const veterinarianEvent = props.events.filter((event: any) => event.name == 'veterinarian' && props.event.pet.name == event.pet.name);
        veterinarianEvent.sort((a: any, b: any) => new Date(formatDateToIso(a.date)).getTime() - new Date(formatDateToIso(b.date)).getTime());
        const grouped = veterinarianEvent.reduce((acc: any, event: any) => {
            if (!event.date) {
                return acc;
            }
            const [day, month, year] = (event.date.split(' ')[0]).split('-')
            const monthKey = `${year}-${String(month).padStart(2, '0')}`;

            if (!acc[monthKey]) {
            acc[monthKey] = [];
                }
            acc[monthKey].push(event);

            return acc;
        }, {});
        setVeterinaryEvents(grouped)
    }, [props.open, props.events])

    useEffect(() => {
        function handleEscapeKey(event: KeyboardEvent) {
            if (event.code === 'Escape') {
                props.onClose()
            }
        }

        document.addEventListener('keyup', handleEscapeKey)
        return () => document.removeEventListener('keyup', handleEscapeKey)
    }, [])

    const EventDisplay = (event: any) => {
        return (
            <>
                {Object.keys(event.event).map((date: string, index: number) => {
                    return (
                        <>
                            <Divider
                                orientation='left' orientationMargin="0" className='event-date'
                                style={{ fontSize: '17px', marginRight: '15px', marginLeft: '15px', marginBottom: '20px', borderColor: '#d4d4d4' }}
                            >
                                {displayMonthYear(date, t)}
                            </Divider>
                            {event.event[date].map((event: any, key: any) => (
                                <div key={key}>
                                    <Flex style={{ margin: 0, marginRight: 2, marginLeft: 2 }}>
                                        <Card
                                            key={key}
                                            style={{
                                                marginBottom: '10px',
                                                height: 'auto',
                                                cursor: 'pointer',
                                                boxShadow: 'rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.1) 0px 1px 1px 1px',
                                                width: '98%',
                                                borderColor: event._id == selectedAppointment ? customTheme.colorPrimary : ""
                                            }}
                                            onClick={() => {
                                                if (event._id == selectedAppointment) {
                                                    setSelectedAppointment("")
                                                } else {
                                                    setSelectedAppointment(event._id)
                                                }
                                            }}
                                            hoverable
                                        >
                                            <Flex justify="space-between" style={{ alignItems: 'center' }}>
                                                <Flex justify='start' style={{ flexDirection: 'row', alignItems: 'center' }} gap={1}>
                                                    <PetAvatar species={event.pet.species} url={event.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                                                    {' '}
                                                    <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                                                    <Typography.Text style={{ fontSize: '17px' }}>{(event.date).split(" ")[0]} - {getTranslatedEventType(event.name, t)}</Typography.Text>
                                                </Flex>
                                                {event._id == selectedAppointment &&
                                                    <Badge color={customTheme.colorPrimary} style={{ position: 'absolute', right: '15px' }}/>
                                                }
                                            </Flex>
                                        </Card>
                                    </Flex>
                                </div>
                            ))}
                        </>
                    );
                })}
            </>
        );
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Badge: {
                            statusSize: 7
                        }
                    }
                }}
            >
                {isModalVisible && <CalendarModal selectedEvent={"appointment"} onSubmit={handleAppointmentSubmit}/>}
                <Modal
                    title={t('treatment-link.link-treatment')}
                    open={props.open}
                    onCancel={props.onClose}
                    destroyOnClose
                    footer={
                        <Flex justify="end" gap={8}>
                            <Button onClick={props.onClose}>
                                {t("form.cancel")}
                            </Button>
                            <Button type="primary" disabled={selectedAppointment == ""} onClick={() => handleSubmit()}>
                                {t("form.link")}
                            </Button>
                        </Flex>
                    }
                >
                    <Flex style={{ flexDirection: 'column', maxHeight: '50dvh', overflow: 'hidden', overflowY: 'scroll' }}>
                        {Object.keys(veterinaryEvents).length > 0
                            ?   <>
                                    <EventDisplay event={veterinaryEvents}/>
                                </>
                            :   <>
                                    <Typography.Text>{t('treatment-link.no-appointment')}</Typography.Text>
                                </>
                        }
                    </Flex>
                    <Divider orientation={"left"} style={{ marginBottom: 0 }}>
                        <Button
                            type="primary"
                            onClick={() => setModalVisibility(true)}
                        >
                            {t("appointment-creation.add-appointment")}
                        </Button>
                    </Divider>
                </Modal>
            </ConfigProvider>
        </>
    )
}

export default LinkModal;