import React, { useEffect, useState } from "react"
import { Button, Card, Divider, Drawer, Flex, Typography } from "antd";
import { ExclamationCircleOutlined, InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import CalendarModal from "../../calendar/modal/CalendarModal";
import { ReactComponent as PetProtection } from "../../../assets/icons/healthRecord/PetProtection.svg";
import { HealthDrawer } from "../HealthSection";
import PetAvatar from "../../pets/PetAvatar";
import { customTheme } from "../../../config/theme";
import { isDateToday } from "../../weight/drawer/WeightDrawer";
import { EventDrawerTreatment } from "../../calendar/drawer/TreatmentDrawer";
import { isImportant } from "../../calendar/CalendarDisplay";
import { formatDateToIso } from "../../calendar/comingEvents/ComingEvents";

function ReminderDrawer(props: HealthDrawer) {
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const [vaccine, setVaccine] = useState([]);
    const [deworming, setDeworming] = useState([]);
    const [antiParasitic, setAntiParasitic] = useState([]);
    const [drawerEvent, setDrawerEvent] = useState<any>();

    const treatments = [
        {var: vaccine, name: "vaccine"},
        {var: deworming, name: "deworming"},
        {var: antiParasitic, name: "anti-parasitic"}
    ]

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const handleModalSubmit = async (submit: boolean) => {
        setModalVisibility(false);
        if (submit && props.renew) {
            props.renew()
        }
    }

    const onDrawerClose = () => {
        setTimeout(() => {
            setOpenDrawer(false);
        }, 250);
    }

    const filterEvents = () => {
        const today = new Date().setHours(0, 0, 0, 0);

        props.events.forEach((event: any) => {
            if (event.type == "T") {
                const eventDate = new Date(formatDateToIso(event.date)).getTime();

                event.important = isImportant(event, t);
                if (eventDate > today || event.name == 'vaccine') {
                    event.showAffiliate = event.to_link;
                }
            }
        });

        setVaccine(
            props.events.filter((event: any) => event.name == "vaccine")
        )
        setDeworming(
            props.events.filter((event: any) => event.name == "deworming")
        )
        setAntiParasitic(
            props.events.filter((event: any) => event.name == "anti-parasitic")
        )
    }

    const analyzeTreatment = (treatment: any) => {
        if (!treatment || treatment.length == 0) {
            return t('prevention-vaccines.no-ongoing-treatment')
        }
        const lastEvent = treatment[treatment.length - 1];

        const [day, month, year] = lastEvent.date.split('-')
        const eventDate = new Date(`${year}/${month}/${day}`)

        const todayDate = new Date().setHours(0, 0, 0, 0);
        if (eventDate.getTime() >= todayDate) {
            return t('prevention-vaccines.ongoing')
        }
        if (lastEvent.to_renew == true) {
            return t('prevention-vaccines.to-validate')
        }
        return t('prevention-vaccines.no-ongoing-treatment')
    }

    useEffect(() =>  {
        filterEvents();
    }, [props.events])

    return (
        <>
            {isModalVisible && <CalendarModal selectedEvent={"treatment-reminder"} onSubmit={handleModalSubmit}/>}
            {openDrawer && <EventDrawerTreatment onClose={onDrawerClose} event={drawerEvent} renew={props.renew} events={props.events}/>}
            <Drawer
                className="health-record-drawer"
                onClose={() => handleClose()}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
                title={
                    <>
                        <Flex style={{ width: '30px'}}>
                            <PetProtection/>
                        </Flex>
                        {t("health-records-page.prevention-vaccines")}
                    </>
                }
                footer={
                    <Flex justify="end">
                        <Button type="primary" onClick={() => setModalVisibility(true)}>
                            {t("treatment-reminder-creation.add-treatment-reminder")}
                        </Button>
                    </Flex>
                }
            >
                <Card
                    style={{ flexDirection: 'column', backgroundColor: 'rgba(197, 194, 194, 0.5)', borderRadius: '10px', color: 'black' }}
                >
                    <Flex style={{ flexDirection: 'column', marginTop: 10, marginBottom: 10 }} gap={15}>
                        <Typography.Text>
                            <Flex style={{ flexDirection: 'row', alignItems: 'center' }} gap={5}>
                                <span style={{ fontWeight: 'bold' }}>
                                    {t('treatment-reminder-creation.treatment-reminder-type.vaccine')}
                                </span>
                                {' - '} {analyzeTreatment(vaccine)}
                            </Flex>
                        </Typography.Text>
                        <Divider style={{ margin: -5 }}/>
                        <Typography.Text>
                            <Flex style={{ flexDirection: 'row', alignItems: 'center' }} gap={5}>
                                <span style={{ fontWeight: 'bold' }}>
                                    {t('treatment-reminder-creation.treatment-reminder-type.deworming')}
                                </span>
                                {' - '} {analyzeTreatment(deworming)}
                            </Flex>
                        </Typography.Text>
                        <Divider style={{ margin: -5 }}/>
                        <Typography.Text>
                            <Flex style={{ flexDirection: 'row', alignItems: 'center' }} gap={5}>
                                <span style={{ fontWeight: 'bold' }}>
                                    {t('treatment-reminder-creation.treatment-reminder-type.anti-parasitic')}
                                </span>
                                {' - '} {analyzeTreatment(antiParasitic)}
                            </Flex>
                        </Typography.Text>
                    </Flex>
                </Card>
                {treatments.map((treatment: any, i: number) => (
                    <Flex style={{ flexDirection: 'column' }} key={i}>
                        <Divider orientation="left">{t(`treatment-reminder-creation.treatment-reminder-type.${treatment.name}`)}</Divider>
                        {treatment.var && treatment.var.length > 0
                            ?  <Flex style={{ flexDirection: 'column' }} gap={5}>
                                    {treatment.var.map((event: any, j: number) => (
                                        <Card
                                            key={j}
                                            style={{
                                                height: 'auto',
                                                minWidth: '100px',
                                                boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                                            }}
                                            hoverable
                                            onClick={() => {
                                                setDrawerEvent(event);
                                                setOpenDrawer(true);
                                            }}
                                        >
                                            <Flex
                                                style={{ alignItems: 'center', cursor: 'pointer', width: '100%' }}
                                                gap={10}
                                            >
                                                <PetAvatar species={event.pet.species} url={event.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                                                {' '}
                                                <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                                                <Typography.Text className='drawer-weight-text-display' style={{ fontSize: '17px' }}>
                                                    {(event.date).replaceAll('/', '-')}
                                                    {isDateToday(event.date.split()[0]) ? ` (AUJOURD'HUI)` : '' } - {t(`treatment-reminder-creation.treatment-reminder-type.${event.name}`)}
                                                </Typography.Text>
                                                <Flex style={{ position: 'absolute', right: '10px' }} gap={5}>
                                                    {event.important && (
                                                        <ExclamationCircleOutlined
                                                            style={{
                                                            color: 'red',
                                                            fontSize: '18px',
                                                            }}
                                                        />
                                                    )}
                                                    {event.showAffiliate &&
                                                        <InfoCircleOutlined
                                                        style={{
                                                            color: 'blue',
                                                            fontSize: '18px',
                                                        }}
                                                        />
                                                    }
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    ))}
                                </Flex>
                            : <Typography.Text style={{ fontSize: '15px' }}>{t(`prevention-vaccines.no-data.${treatment.name}`)}</Typography.Text>
                        }
                    </Flex>
                ))}
            </Drawer>
        </>

    );
}

export { ReminderDrawer }
