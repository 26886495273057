import {BrowserRouter as Router} from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import ReactGA from "react-ga4";
import App from './App';
import Clarity from '@microsoft/clarity';

import './index.css';
import './lang/i18n';


Clarity.init("p0ia2uy8kl");
ReactGA.initialize("G-MST3HDSB3T");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH_DOMAIN as string}
        clientId={process.env.REACT_APP_AUTH_CLIENT_ID as string}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/application`,
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "read:current_user update:users"
        }}
        useRefreshTokens={true}
      >
        <App />
      </Auth0Provider>
    </Router>
  </React.StrictMode>
);
