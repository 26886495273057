import { Checkbox, Divider, Flex, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import PetAvatar, { assignPetAvatar, savePetAvatars } from "../../pets/PetAvatar";
import { useGlobalState } from "../../../state/globalState";
import { useTranslation } from "react-i18next";
import AddPet from "../../pets/AddPet";
import { getPetsFromUser } from "../../../request/pets";
import { useAuth0 } from "@auth0/auth0-react";

interface AddingModalProps {
    title: string;
    open: boolean;
    onCancel: () => void;
    onOk: (values: string[]) => void;
    description: string;
    familyPets: string[];
}

function AddingPetModal(props: AddingModalProps) {
    const [values, setValues] = useState<string[]>([]);
    const [petList, setPetList] = useState<any[]>([]);
    const [userPets, setUserPets] = useGlobalState('userPets');
    const { t } = useTranslation();
    const [renew, setRenew] = useState(false);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getAccessToken = async () => {
        if (isAuthenticated) {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
                    scope: "openid profile email",
                },
            });
            return accessToken;
        }
        return "";
    }

    async function synchronizePets() {
        const result = await getPetsFromUser(await getAccessToken());

        await savePetAvatars(result.avatars, true);
        const pets = await assignPetAvatar(result.pets, "_id", "avatar", true);
        setUserPets(pets);
    }

    async function initPetList() {
        const result = userPets.filter((pet: any) => pet.type !== "family");
        setPetList(result);
    }

    useEffect(() => {
        initPetList();
    }, [userPets]);

    useEffect(() => {
        if (renew)
            synchronizePets();
        setRenew(false);
    }, [renew]);

    return (
        <Modal
            title={props.title}
            open={props.open}
            onCancel={props.onCancel}
            onOk={() => props.onOk(values)}
            okText={t("form.add")}
            cancelText={t("form.cancel")}
            okButtonProps={{disabled: values.length === 0}}
        >
            <Flex vertical>
                <Typography.Text>{props.description}</Typography.Text>
                {petList.length > 0 &&
                    <Flex vertical align="left" gap={"small"} style={{marginTop: "10px"}}>
                        {petList.map((pet) => {
                            return (
                                <Checkbox
                                    key={pet._id}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setValues([...values, pet._id]);
                                        } else {
                                            setValues(values.filter((value) => value !== pet._id));
                                        }
                                    }}
                                    disabled={props.familyPets.includes(pet._id)}
                                >
                                    <Flex align="center" justify="space-evenly" gap={"small"}>
                                        <PetAvatar species={pet.species} iconSize={{width: "50px", height: "50px"}} url={pet.avatar} />
                                        <Typography.Text style={{fontSize: "17px", fontWeight: "bold"}}>{pet.name}</Typography.Text>
                                    </Flex>
                                </Checkbox>
                            )
                        })}
                        <Divider>{t("linkers.or")}</Divider>
                    </Flex>
                }
                <AddPet renew={setRenew} setStyle={false}/>
            </Flex>
        </Modal>
    )
}

export default AddingPetModal;