import { Flex, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { customTheme } from "../../config/theme";
import { CheckOutlined } from "@ant-design/icons";

function PetProfileCompletion({ percentage }: {percentage: number}) {
  const {t} = useTranslation();

  const size = 50;

  const Circle = ({ color, percentage }: any) => {
      const r = 10;
      const circ = 2 * Math.PI * r;
      const strokePct = ((100 - percentage) * circ) / 100;

      return (
        <circle
          r={r}
          cx={(70 / 2)}
          cy={(40 / 2)}
          fill="transparent"
          stroke={percentage != 0 ? color : ""}
          strokeWidth={"5px"}
          strokeDasharray={circ}
          strokeDashoffset={percentage ? strokePct : 0}
        />
      );
    };

    const Text = ({ percentage }: any) => {
      return (
        <text
          x="50%"
          y="88%"
          dominantBaseline="central"
          textAnchor="middle"
          fontSize={"14px"}
          fill={percentage == 0 ? "lightgrey" : (percentage == 100 ? '#30d155' : customTheme.colorPrimary)}
          fontWeight={"600"}
        >
          {percentage}%
        </text>
      );
    };

    return (
      <Tooltip title={t("pet-profile.completion")}>
        <Flex style={{ cursor: 'pointer' }}>
          <svg width={40} height={41}>
            <g transform={`rotate(-90 ${`${size / 2} ${size / 2}`})`}>
              <Circle color="lightgrey" />
              <Circle
                color={(percentage == 100 ? '#30d155' : customTheme.colorPrimary)}
                percentage={percentage}
              />
            </g>
            <Text percentage={percentage}/>
          </svg>
        </Flex>
      </Tooltip>
    );
}


export default PetProfileCompletion
