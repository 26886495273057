import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Flex, Modal, Select, Typography } from 'antd';
import { FaPause, FaPlay } from 'react-icons/fa';
import { GrPowerReset } from "react-icons/gr";
import { CiCirclePlus } from "react-icons/ci";
import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { PiFootprintsDuotone } from "react-icons/pi";
import { PiTennisBallDuotone } from "react-icons/pi";
import { PiMountainsDuotone } from "react-icons/pi";
import { PiTrophyDuotone } from "react-icons/pi";
import { useTranslation } from 'react-i18next';

import './activity.css'
import { customTheme } from '../../config/theme';
import { ActivityType } from './activityType';

type Props = {
  onSubmit: () => void,
  setHours: any,
  setMinutes: any,
  setSeconds: any,
  isChronoRunning?: boolean,
  setIsChronoRunning?: React.Dispatch<React.SetStateAction<boolean>>,
  setActivityType: React.Dispatch<React.SetStateAction<string>>
}

function ActivityTimePicker(props: Props) {
  const {t} = useTranslation();

  const [modalVisibility, setModalVisibility] = useState(false);

  const generateOptions = (max: any) => {
    const options: string[] = [];

    for (let i = 0; i < 3; i++) {
      for (let j = 0; j <= max; j++) {
        const value = j < 10 ? `0${j}` : `${j}`;

        options.push(value);
      }
    }
    return options;
  };

  const [h, setH] = useState<string>('00');
  const [m, setM] = useState<string>('00');
  const [s, setS] = useState<string>('00');

  const hours: string[] = generateOptions(23);
  const minutesSeconds: string[] = generateOptions(59);
  const times: any[] = [
    {time: hours, setValue: props.setHours, inputId: 'hour-selection'},
    {time: minutesSeconds, setValue: props.setMinutes, inputId: 'minute-selection'},
    {time: minutesSeconds, setValue: props.setSeconds, inputId: 'second-selection'}
  ];


  const infiniteScroll = (event: any, index: number) => {
    const idArray = ["hour-selection", "minute-selection", "second-selection"];
    const indexPos = [index == 0 || index == 69, index == 0 || index == 177, index == 0 || index == 177]
    const scrollTo = [(index == 0 ? 960 : 1800), (index == 0 ? 2400 : 4680), (index == 0 ? 2400 : 4680)]

    for (let i = 0; i < 3; i++) {
      if (event.target.id == idArray[i] && indexPos[i]) {
        const selection = document.querySelector(`#${idArray[i]}`);

        if (selection) {
          selection.scrollTop = scrollTo[i];
        }
        return;
      }
    }
    return;
  }

  const handleDisable = (id: string, index: number) => {
    if (id === "hour-selection") {
      setH(hours[index + 1]);
    } else if (id === "minute-selection") {
      setM(minutesSeconds[index + 1]);
    } else {
      setS(minutesSeconds[index + 1]);
    }
  }

  const handleScroll = (event: any, setValue: any) => {
    const optionHeight = 40;
    const index = Math.round(event.target.scrollTop / optionHeight);

    infiniteScroll(event, index);
    if (event.target.id === "hour-selection") {
      setValue(hours[index + 1]);
    } else {
      setValue(minutesSeconds[index + 1]);
    }
    handleDisable(event.target.id, index);
  };

  const resetScroll = () => {
    const hourSelection = document.querySelector('#hour-selection');
    const minuteSelection = document.querySelector('#minute-selection');
    const secondSelection = document.querySelector('#second-selection');

    if (hourSelection && minuteSelection && secondSelection) {
      hourSelection.scrollTop = 920;
      minuteSelection.scrollTop = 2360;
      secondSelection.scrollTop = 2360;
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultHoverColor: customTheme.colorPrimary,
          }
        }
      }}
    >
      <Flex justify='center' style={{ flexDirection: 'column', alignItems: 'center', width: '130px'}} gap={8}>
        <Button
          className='activity-btn-launch'
          type='primary' onClick={() => setModalVisibility(true)}
          style={{
            fontWeight: 'bold', borderRadius: '25px', width: '50px', height: '50px',
            backgroundColor: customTheme.colorPrimary
          }}
        >
          <PlusCircleOutlined style={{ color: 'white', fontSize: '30px' }}/>
        </Button>
        <Typography.Text style={{ fontWeight: 'bold', textAlign: 'center' }}>{t("activity-section.enter-activity")}</Typography.Text>
      </Flex>
      <Modal
        afterOpenChange={resetScroll}
        title={t("activity-section.activity-time-picker")}
        open={modalVisibility}
        okText={t("form.add")}
        cancelText={t("form.cancel")}
        onCancel={() => setModalVisibility(false)}
        onOk={() => {
          props.onSubmit()
          setModalVisibility(false)
        }}
        okButtonProps={{ disabled: (h == "00" && m == '00' && s == "00" )}}
      >
        <Flex
            className='activity-picker-container'
            justify='center'
            style={{ height: '120px', alignItems: 'center', borderRadius: '15px', marginBottom: 20, marginTop: 20 }}
        >
          <Flex
            className='selected-time'
            style={{ width: '90%', height :'30px', position: 'absolute', borderBottom: '1px solid rgb(238, 238, 238)', borderTop: '1px solid rgb(238, 238, 238)' }}
          >
            {' '}
          </Flex>
          {times.map((value: any, index: number) => (
            <Flex key={index} style={{ flexGrow: 1, margin: '0 10px', justifyContent: 'center' }}>
              <div
                id={value.inputId}
                onScroll={(e) => handleScroll(e, value.setValue)}
                style={{ height: '120px', width: '100%', overflowY: 'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none', scrollSnapType: 'y mandatory', zIndex: '998' }}
              >
                {value.time.map((optionValue: string, index: number) => (
                  <div
                      key={index}
                      style={{ height: '40px', textAlign: 'left', paddingLeft: '15%', lineHeight: '40px', scrollSnapAlign: 'start' }}
                  >
                    {optionValue}
                    {value.inputId === 'hour-selection' && h === optionValue
                      ? (h === '00' || h === '01'
                          ? ` ${t("activity-section.time.hour")}`
                          : ` ${t("activity-section.time.hour")}s`)
                      : ''}
                    {value.inputId === 'minute-selection' && m === optionValue
                      ? (m === '00' || m === '01'
                          ? ` ${t("activity-section.time.minute")}`
                          : ` ${t("activity-section.time.minute")}s`)
                      : ''}
                    {value.inputId === 'second-selection' && s === optionValue
                      ? (s === '00' || s === '01'
                          ? ` ${t("activity-section.time.second")}`
                          : ` ${t("activity-section.time.second")}s`)
                      : ''}
                  </div>
                ))}
              </div>
            </Flex>
          ))}
        </Flex>
        <SelectActivityType setValue={props.setActivityType}/>
      </Modal>
    </ConfigProvider>
  );
};

function ActivityChronoPicker(props: Props) {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const handleStop = () => {
    props.setHours(String(hours).padStart(2, '0'));
    props.setMinutes(String(minutes).padStart(2, '0'));
    props.setSeconds(String(seconds).padStart(2, '0'));
  }

  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      intervalId = setInterval(() => setTime(time + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  const hours: number = Math.floor(time / 360000);
  const minutes: number = Math.floor((time % 360000) / 6000);
  const seconds: number = Math.floor((time % 6000) / 100);
  const milliseconds: number = time % 100;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultHoverColor: customTheme.colorPrimary,
          }
        }
      }}
    >
      <Flex
        className='activity-picker-container'
        justify='center'
        style={{ height: '160px', flexDirection: 'column', backgroundColor: 'rgba(197, 194, 194, 0.5)', borderRadius: '15px', width: '100%' }}
      >
        <Flex justify='space-between'>
          <Flex style={{ marginLeft: '8px' }}>
            <Button
              onClick={() => {
                if (props.setIsChronoRunning) {
                  props.setIsChronoRunning(false);
                }
                setTime(0);
              }}
              style={{
                border: 'none',
                boxShadow: 'none',
                height: '25px', width: '25px',
                backgroundColor: 'transparent',
              }}
              icon={<LeftOutlined/>}
            />
          </Flex>
          <Flex style={{ marginRight: '8px' }}>
            <Button
              onClick={() => {
                props.onSubmit();
                setTime(0);
              }}
              style={{
                border: 'none',
                borderRadius: '20px',
                height: '25px', width: '25px',
                backgroundColor: 'transparent',
                cursor: `${isRunning || [hours, minutes, seconds, milliseconds].every((v) => { return v == 0 }) ? 'default' : 'pointer'}`
              }}
              icon={<CiCirclePlus style={{ fontSize: '30px', marginTop: '2px' }}/>}
              disabled={isRunning || [hours, minutes, seconds, milliseconds].every((v) => { return v == 0 })}
            />
          </Flex>
        </Flex>
        <Typography.Text style={{ fontSize: '30px',  textAlign: 'center'  }}>
          {hours}:
          {minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}:
          {milliseconds.toString().padStart(2, "0")}
        </Typography.Text>
        <Flex justify='center' gap={5}>
          <Button
            style={{ backgroundColor: customTheme.colorPrimary, border: 'none' }}
            onClick={() => {
              if (isRunning) {
                handleStop();
              }
              setIsRunning(!isRunning)
            }}
            icon={isRunning ? (
              <FaPause style={{ color: 'white' }}/>
            ) : (
              <FaPlay style={{ color: 'white' }}/>
            )}
          />
          <Button
            style={{ backgroundColor: customTheme.colorPrimary, border: 'none' }}
            onClick={() => setTime(0)}
            icon={<GrPowerReset style={{ color: 'white', fontSize: '18px' }}/>}
          />
        </Flex>
        <SelectActivityType setValue={props.setActivityType}/>
      </Flex>
    </ConfigProvider>
  );
};

interface ActivityTypes {
  setValue: React.Dispatch<React.SetStateAction<string>>
  selected?: string,
  width?: string
}

function SelectActivityType(settings: ActivityTypes) {
  const {t} = useTranslation();

  return (
      <Flex justify='center' style={{ width: '100%', marginTop: '10px' }}>
          <Select
            style={{ width: settings.width || '90%' }}
            onSelect={(e) => settings.setValue(e)}
            placeholder={t("appointment-creation.select-type")}
            {...settings.selected ? {defaultValue: settings.selected} : null}
          >
            {ActivityType(t).map((value: any, key: number) => (
              <Select.Option key={key} value={value.value}>
                <Flex justify='start' style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
                  <value.icon/>
                  {value.label}
                </Flex>
              </Select.Option>
            ))}
          </Select>
      </Flex>
  );
}

export {ActivityTimePicker, ActivityChronoPicker, SelectActivityType}
