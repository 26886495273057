import { Button, Checkbox, ConfigProvider, Divider, Dropdown, Menu } from "antd";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";

import { getPetsFromUser } from "../../request/pets";
import { useGlobalState } from "../../state/globalState";
import { customTheme } from "../../config/theme";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

function Filter(props: any) {
  const { getAccessTokenSilently } = useAuth0();
  const {t} = useTranslation();

  const [checked, setChecked] = useState<{[key: number]: boolean}>({});
  const [userPets, setUserPets] = useGlobalState('userPets');

  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);

  const setFilterCheck = (key: number) => {
    setChecked(prevState => {
      const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
          acc[index] = checked[index];
          return acc;
      }, {});
      newState[key] = !checked[key];
      return newState;
    });
  }

  const handleFilter = (value: string, key: number) => {
    let updatedFilter;

    if (props.filter.includes(value)) {
      updatedFilter = props.filter.filter((items: string) => items !== value);
    } else {
      updatedFilter = [...props.filter, value];
    }
    props.filterChange(updatedFilter);
    setFilterCheck(key);

    setCheckedValues(prevValues =>
      prevValues.includes(value) ? prevValues.filter(item => item !== value) : [...prevValues, value]
    );
  };

  const getPetList = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
        scope: "read:current_user",
      },
    });
    const response = await getPetsFromUser(accessToken);

    setUserPets(response.pets || []);
  }

  useEffect(() => {
    getPetList();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Checkbox
          value={"A"}
          onChange={() => handleFilter('A', 0)}
          checked={checkedValues.includes('A')}
        >
          {t("event-page.appointment")}
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="1">
        <Checkbox
          value={"T"}
          onChange={() => handleFilter('T', 1)}
          checked={checkedValues.includes('T')}
        >
          {t("event-page.treatment-reminder")}
        </Checkbox>
      </Menu.Item>
      <Divider style={{ marginTop: '5px', marginBottom: '5px' }}/>
      {userPets && userPets.map((val: any, key: number) => (
        <Menu.Item key={key + 2}>
          <Checkbox
            value={val.name}
            onChange={() => handleFilter(val.name, key + 2)}
            checked={checkedValues.includes(val.name)}
          >
            {val.name}
          </Checkbox>
        </Menu.Item>
      ))}
      </Menu>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: customTheme.colorPrimary,
          },
        }}
      >
        <Dropdown
          overlay={menu}
          trigger={['click']}
          visible={visible}
          onVisibleChange={(e) => setVisible(e)}
        >
          <Button>
            <CiFilter
              style={{ width: '20px', height: '20px' }}
            />
          </Button>
        </Dropdown>
      </ConfigProvider>
    </div>
  );
}

export { Filter };