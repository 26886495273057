import React, { useState } from 'react';
import { Badge, Calendar, ConfigProvider, Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import type { BadgeProps, CalendarProps } from 'antd';
import type { Dayjs } from 'dayjs';

import './gridView.css'
import { EventDrawerGridView } from './drawer/GridViewEventDrawer';
import { localeConf } from './localeConf';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { customTheme } from '../../config/theme';

const formatDateTime = (dateToFormat: string) => {
    const [date, time] = dateToFormat.split(' ');
    const [day, month, year] = date.split('-');
    if (!time) {
        return dayjs(`${year}-${month}-${day}`)
    }

    return dayjs(`${year}-${month}-${day} ${time}`)
}

const getListData = (value: Dayjs, events: any, t: TFunction<"translation", undefined>) => {
    const listData: { content: string }[] = [];

    const year = value.get('year');
    const month = value.get('month');
    let monthEvent = events.upcomingEvents[`${String(year)}-${String(month + 1).padStart(2, '0')}`] || [];
    monthEvent = monthEvent.concat(events.pastEvents[`${String(year)}-${String(month + 1).padStart(2, '0')}`] || [])

    if (monthEvent) {
      for (const day of monthEvent) {
        const appointmentTranslateSearch = `appointment-creation.appointment-type.${day.name}`
        const treatmentReminderTranslateSearch = `treatment-reminder-creation.treatment-reminder-type.${day.name}`

        if (value.isSame(formatDateTime(day.date), 'day')) {
          if (day.type == 'T') {
            listData.push({
              content: treatmentReminderTranslateSearch != t(treatmentReminderTranslateSearch)
                ? t(treatmentReminderTranslateSearch)
                : day.name
            })
          } else {
            listData.push({
              content: appointmentTranslateSearch != t(appointmentTranslateSearch)
                ? t(appointmentTranslateSearch)
                : day.name
            })
          }
        }
      }
    }
    return listData;
};

function GridView(props: any) {
  const {t} = useTranslation();

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerEvent, setDrawerEvent] = useState<{events: any, month: string}>();

  const dateCellRender = (value: Dayjs) => {
    const listData: { content: string }[] = getListData(value, props.events, t);

    return (
        <ul className="events">
          <Flex style={{ flexDirection: 'column' }}>
            {listData.map((item) => (
              <Flex gap={5}>
                <Badge color={customTheme.colorPrimary}/>
                <Typography.Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.content}</Typography.Text>
              </Flex>


            ))}
          </Flex>
        </ul>
    );
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  const handleChange = (date: Dayjs ) => {
    let monthEvent = props.events.upcomingEvents[date.format('YYYY-MM')] || [];
    monthEvent = monthEvent.concat(props.events.pastEvents[date.format('YYYY-MM')] || [])
    const dayEvent: any = [];

    if (monthEvent.length > 0) {
        for (const day of monthEvent) {
            if (date.isSame(formatDateTime(day.date), 'day')) {
                dayEvent.push(day);
            }
        }
    }
    setDrawerEvent({events: dayEvent, month: date.format('YYYY-MM-DD')})
    if (dayEvent.length > 0) {
        setOpenDrawer(true);
    }
  }

  const onDrawerClose = () => {
    setTimeout(() => {
      setOpenDrawer(false)
    }, 250);
  }

  return (
    <div style={{ width: '100%', height: '100%'}}>
      {openDrawer && <EventDrawerGridView onClose={onDrawerClose} events={drawerEvent} renew={props.renew}/>}
      <Calendar
        style={{ backgroundColor: 'transparent', height: '100%', marginRight: '10px', marginLeft: '10px' }}
        cellRender={cellRender}
        onSelect={handleChange}
        mode='month'
        locale={localeConf}
      />
    </div>
  );
};

export default GridView;