import React, { useEffect, useState } from 'react';
import { getPetsFromUser } from '../../request/pets';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import { Divider, Flex, Input, List, Space, Typography } from 'antd';
import { DownOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './pets.css'
import { useGlobalState } from '../../state/globalState'
import InfoContainer from '../infoContainer/InfoContainer';
import { PetDrawer } from './drawer/PetDrawer';
import PetAvatar, { assignPetAvatar, savePetAvatars } from './PetAvatar';
import PetProfileCompletion from './PetProfileCompletion';
import { useAuth0 } from '@auth0/auth0-react';

export function formatAge(date: string, t: any) {
  dayjs.extend(relativeTime)
  const months = [
    t("month.january"), t("month.february"), t("month.march"), t("month.april"), t("month.may"), t("month.june"),
    t("month.july"), t("month.august"), t("month.september"), t("month.october"), t("month.november"), t("month.december")
  ];
  const [day, month, year] = date.split('/');

  function calculateDifference(date1: dayjs.Dayjs, date2: dayjs.Dayjs) {
      let start = date1;
      let end = date2;

      let years = end.diff(start, 'year');
      start = start.add(years, 'years');

      let months = end.diff(start, 'month');
      start = start.add(months, 'months');

      let days = end.diff(start, 'day');

      return { years, months, days };
  }
  const diff = calculateDifference(dayjs(`${month}-${day}-${year}`).year(Number(year)), dayjs());

  const ageYears = diff.years > 0 ? t('pet-profile.age.year', { count: diff.years }) : '';
  const ageMonths = diff.months > 0 ? t('pet-profile.age.month', { count: diff.months }) : '';
  const ageDays = (diff.years === 0 && diff.months === 0) ? t('pet-profile.age.day', { count: diff.days }) : '';

  const age = [ageYears, ageMonths, ageDays].filter(Boolean).join(' ' + t('linkers.and') + ' ');

  return t("pet-profile.age.sentence", {age: age, birthDate: `${day} ${months[Number(month) - 1]} ${year}`});
}

function PetsDisplay(props: any) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const {t} = useTranslation();

  const [userPet, setUserPet] = useGlobalState('userPets');
  const [reRender, setReRender] = useGlobalState('reRender');
  const [reRenderSelectPet, setReRenderSelectPet] = useGlobalState('reRenderSelectPet');

  const [pets, setPets] = useState([""]);

  const [openVet, setOpenVet] = useState<boolean[]>([]);
  const [openIdentification, setOpenIdentification] = useState<boolean[]>([]);

  const [drawerPet, setDrawerPet] = useState();
  const [openStateDrawer, setOpenStateDrawer] = useState<boolean>(false);

  const getAccessToken = async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "openid profile email",
        },
      });
      return accessToken;
    }
    return "";
  }

  /**
   * Function to get all the pet from the user
   * @param submit a boolean to tell if a pet as been modified, added or deleted
   */
  const renewPets = async (submit: boolean) => {
    const response = await getPetsFromUser(await getAccessToken());

    let avatars: any = localStorage.getItem('petsAvatar')
    avatars = avatars ? JSON.parse(avatars) : {}
    const renewAvatars = Object.keys(avatars).map((avatar, key) => {
      if (!response.avatars[key] || avatar != response.avatars[key].petId) {
        return true
      }
    })
    if (submit || Object.keys(avatars).length != response.avatars.length || renewAvatars.includes(true)) {
      await savePetAvatars(response.avatars, true);
      setReRenderSelectPet("no-re-render");
    }
    const pets = await assignPetAvatar(response.pets, "_id", "avatar", true);

    setPets(pets);
    setUserPet(pets);
  }

  /**
   * Function to close the pet drawer
   * @param reRender a boolean to tell if the pet as been deleted or not
   */
  const onDrawerClose = (reRender: boolean) => {
    setTimeout(() => {
      setOpenStateDrawer(false)
    }, 250);
    if (reRender) {
      renewPets(true);
    }
  }

  /**
   * Function to open the pet drawer and close all other component
   */
  const openDrawer = () => {
    setOpenVet(prevState => {
        const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
            acc[index] = false;
            return acc;
        }, {});
        return newState;
    })
    setOpenIdentification(prevState => {
        const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
            acc[index] = false;
            return acc;
        }, {});
        return newState;
    })
    setOpenStateDrawer(true)
  }

  /**
   * Function to get the profil completion of a specific pet
   * @param pet an object containing all the information of a single pet
   * @returns the percentage of completion of the pet's profile
   */
  const getPetProfileCompletion = (pet: {avatar: string, passport_number: string, pet_id_number: number, vet: object}) => {
    let percentage = 10;
    const varsValue: [string, string, number, number] = [
      pet["avatar"],
      pet["passport_number"],
      pet["pet_id_number"],
      Object.keys(pet["vet"]).length
    ];
    const varsEmptyValue = ["", "", 0, 0];

    for (let i: number = 0; i < varsValue.length; i++) {
      if (varsValue[i] == varsEmptyValue[i]) {
        percentage -= 1;
      }
    }
    return (percentage) * 10;
  }

  useEffect(() => {
    renewPets(props.renew.renew);
  }, [props.renew, reRender]);

  return (
    <div className="Pets">
      {pets[0] != "" && pets.map((pet: any, key: number) => (
        <InfoContainer key={key}>
          <Flex justify='start' style={{ marginTop: '10px', marginBottom: '10px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Flex style={{ flexDirection: 'row', alignItems: 'center', width: 'calc(100% - 122px)' }} gap={10}>
              <PetAvatar species={pet.species} url={pet.avatar} iconSize={{width: "50px", height: "50px"}}/>
              <Typography.Text strong>
                {pet.name}
              </Typography.Text>
            </Flex>
            <Flex justify='center' style={{ flexDirection: 'row', alignItems: 'center', width: '122px' }} gap={7}>
              <PetProfileCompletion percentage={getPetProfileCompletion(pet)}/>
              <Flex
                justify='center'
                style={{ flexDirection: 'row', height: '30px', width: 'auto', cursor: 'pointer', alignItems: 'center' }}
                onClick={() => {
                  setDrawerPet(pet)
                  openDrawer()
                }}
                gap={5}
              >
                <Typography.Text>{t("pet-profile.modify")}</Typography.Text>
                <RightOutlined/>
              </Flex>
            </Flex>
          </Flex>
          <Flex justify='center'>
              <List
                  style={{ width: '90%' }}
                  size="large"
                  dataSource={[
                      `${t("pet-profile.gender")}: ${pet.gender}`,
                      `${t("pet-profile.breed")}: ${pet.breed}`,
                      formatAge(pet.birth_date, t),
                      `${pet.neutered == true ? t("pet-profile.sprayed") : t("pet-profile.unsterilized")}`,
                  ]}
                  renderItem={(item) => <List.Item style={{ padding: '10px' }}>{item}</List.Item>}
              />
          </Flex>
          {Object.keys(pet.vet).length > 0 &&
            <Flex justify='center'>
              <Flex
                onClick={() =>
                  setOpenVet(prevState => {
                    const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                      acc[index] = prevState[index];
                      return acc;
                    }, {});
                    newState[key] = !openVet[key];
                    return newState;
                  })
                }
                style={{ marginTop: '5px', flexDirection: 'column', backgroundColor: 'rgba(197, 194, 194, 0.3)', width: '95%', borderRadius: '10px', padding: '5px', marginBottom: '10px' }}
              >
                <Flex style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography.Text
                    style={{ marginLeft: '10px'}}
                    >
                    {pet.vet.vet_name} {pet.vet.vet_name && pet.vet.vet_clinic ? '-' : ''} {pet.vet.vet_clinic}
                  </Typography.Text>
                  {openVet[key] ? (
                    <UpOutlined style={{ marginRight: '10px'}}/>
                  ) : (
                    <DownOutlined style={{ marginRight: '10px'}}/>
                  )}
                </Flex>
                <div
                  style={{
                    maxHeight: openVet[key] ? '300px' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.5s ease',
                  }}
                >
                  <Divider style={{ margin: 0, marginTop: '5px' }}/>
                  <Flex justify='center' wrap style={{ marginTop: '10px', marginBottom: '10px', gap: '10px'}}>
                    <Input
                      className='vet-info'
                      addonBefore={'Email'}
                      value={pet.vet.vet_email}
                      style={{ width: '90%' }}
                      disabled
                      />
                    <Input
                      className='vet-info'
                      addonBefore={t("pet-profile.vet.phone-number")}
                      value={pet.vet.vet_phone_number}
                      style={{ width: '90%' }}
                      disabled
                    />
                  </Flex>
                </div>
              </Flex>
            </Flex>
          }
          <Flex justify='center'>
            <Flex
              onClick={() =>
                setOpenIdentification(prevState => {
                  const newState = Object.keys(prevState).reduce((acc: any, index: any) => {
                      acc[index] = prevState[index];
                      return acc;
                  }, {});
                  newState[key] = !openIdentification[key];
                  return newState;
                })
              }
              style={{ marginTop: '5px', flexDirection: 'column', backgroundColor: 'rgba(197, 194, 194, 0.3)', width: '95%', borderRadius: '10px', padding: '5px' }}
            >
              <Flex style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography.Text
                  style={{ marginLeft: '10px'}}
                >
                  {t("pet-profile.identification-details")}
                </Typography.Text>
                {openIdentification[key] ? (
                  <UpOutlined style={{ marginRight: '10px'}}/>
                  ) : (
                  <DownOutlined style={{ marginRight: '10px'}}/>
                )}
              </Flex>
              <div
                style={{
                  maxHeight: openIdentification[key] ? '300px' : '0',
                  overflow: 'hidden',
                  transition: 'max-height 0.5s ease',
                }}
              >
                <Divider style={{ margin: 0, marginTop: '5px' }}/>
                <Flex justify='center' wrap style={{ marginTop: '10px', marginBottom: '10px', gap: '10px' }}>
                      <Input
                          className='vet-info'
                          addonBefore={'ID'}
                          value={pet.pet_id_number}
                          style={{ width: '90%' }}
                          disabled
                      />
                      <Input
                          className='vet-info'
                          addonBefore={t("pet-profile.passport-number")}
                          value={pet.passport_number}
                          style={{ width: '90%' }}
                          disabled
                      />
                </Flex>
              </div>
            </Flex>
          </Flex>
          <Divider/>
          <Input.TextArea
            style={{ backgroundColor: 'rgba(197, 194, 194, 0.5)', borderRadius: '5px', color: 'black', cursor: 'default', marginTop: '5px', marginBottom: '10px' }}
            autoSize={{ minRows: 4 }}
            disabled
            value={pet.medical_history}
          >
          </Input.TextArea>
        </InfoContainer>
      ))}
      {openStateDrawer &&
        <PetDrawer onClose={onDrawerClose} pet={drawerPet} renew={renewPets}/>
      }
    </div>
  );
}

export default PetsDisplay;
