import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

type Treatment = {
  pet: { pet_id: string },
  name: string,
  date: string,
  duration: {number: number, unit: string},
  every: {number: number, unit: string},
  treatment: string,
  notes: string
}

async function getEventsFromPets(accessToken: string, pets: any) {
  try {
    const query = pets.join(",");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/event/pets/?pets=${query}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
    return response.data;
  } catch(error: any) {
    console.log(`there was an error: ${error}`);
    return [""];
  }
}

const getEventsFromUser = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/event/all`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
            }
        );
        return response.data;
    } catch (error: any) {
        console.log(`there was an error: ${error}`);
        return [""];
    }
}

const getEventsFromSelectedPet = async (accessToken: string) => {
  try {
      const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/event/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
          }
      );
      return response.data;
  } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [""];
  }
}

const createTreatment = async (accessToken: string, param: Treatment) => {
  try {
    const {pet, name, date, duration, every, treatment, notes} = param
      const data = JSON.stringify({pet, name, date, duration, every, treatment, notes}, null, 1)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/event/treatment`,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
    throw error;
  }
}

const createAppointment = async (accessToken: string, pet: { pet_id: string }, name: string, date: string, motive: string, notes: string) => {
  try {
    const data = JSON.stringify({pet, name, date, motive, notes}, null, 1)
    const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/event/appointment`,
      data,
      {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
      },
    );
  } catch (error: any) {
    throw error;
  }
}

const getNotes = async (accessToken: string, eventId: string) => {
  try {
    console.log('je suis dans les requête notes')
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/event/notes/${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
          },
      );
    return response;
  } catch (error: any) {
    throw error;
  }
}

const updateEvent = async (accessToken: string, pet: { pet_id: string }, date: string, treatment: string, motive: string, notes: string, eventId: string) => {
  try {
    const data = JSON.stringify({pet, date, treatment, motive, notes, eventId}, null, 1)
    const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/event/`,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
    throw error;
  }
}

const removeLink = async (accessToken: string, treatmentId: string, appointmentId: string) => {
  try {
    const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/event/treatment/link/${treatmentId}/${appointmentId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
        },
      );
  } catch (error: any) {
    throw error;
  }
}

const validateTreatment = async (accessToken: string, eventId: string) => {
  try {
    const data = JSON.stringify({eventId}, null, 1)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/event/validate`,
        data,
        {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
        },
    );
  } catch (error: any) {
    throw error;
  }
}

const linkTreatment = async (accessToken: string, treatmentId: string, appointmentId: string) => {
  try {
    const data = JSON.stringify({treatmentId, appointmentId}, null, 1)
    await axios.put(
      `${process.env.REACT_APP_API_URL}/event/treatment/link`,
        data,
        {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            withCredentials: true
        },
    );
  } catch (error: any) {
    throw error;
  }
}

const deleteEvent = async (accessToken: string, id: string) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/event/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true,
        }
      );
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
    }
}

export {
  getEventsFromUser,
  getEventsFromPets,
  getEventsFromSelectedPet,
  deleteEvent,
  createTreatment,
  createAppointment,
  removeLink,
  updateEvent,
  linkTreatment,
  validateTreatment
}