import axios from "axios";

const getFamilies = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/family`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

const createFamily = async (accessToken: string, name: string) => {
    try {
        const data = {
            name: name,
        }
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/family`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const addMemberToFamily = async (accessToken: string, familyId: string, email: string) => {
    try {
        const data = {
            email: email
        }
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/family/member/${familyId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const addPetToFamily = async (accessToken: string, familyId: string, pet_id: string) => {
    try {
        const data = {
            pet: pet_id
        }
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/family/pet/${familyId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const updateFamily = async (accessToken: string, familyId: string, name: string, owner: string) => {
    try {
        const data = {
            name: name,
            owner: owner
        }
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/family/${familyId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const deleteFamily = async (accessToken: string, familyId: string) => {
    try {
        const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/family/${familyId}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const removeMemberFromFamily = async (accessToken: string, familyId: string, email: string) => {
    try {
        const data = {
            email: email
        }
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/family/member/${familyId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const removePetFromFamily = async (accessToken: string, familyId: string, pet_id: string) => {
    try {
        const data = {
            pet: pet_id
        }
        console.log(data);
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/family/pet/${familyId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const familyAPI = {
    getFamilies,
    createFamily,
    addMemberToFamily,
    addPetToFamily,
    updateFamily,
    removeMemberFromFamily,
    removePetFromFamily,
    deleteFamily
};

export default familyAPI;