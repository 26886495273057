import React, { useState } from 'react';
import { Modal, Button, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { updateUserEmail } from '../../../request/user/user';
import { useAuth0 } from '@auth0/auth0-react';

function EmailModal(props: any) {
    const {user, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const {t} = useTranslation();

    const [form] = Form.useForm();

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const response = await updateUserEmail(await getAccessToken(), values.email, user?.sub || "");

            if (response && response?.status !== 200) {
                message.error(t("my-profile.email-modification.error"));
            } else {
                message.success(t("my-profile.email-modification.success"));
            }
            form.resetFields();
            props.onFinish(values.email);
        } catch (errorInfo) {
            console.log('Validation Failed:', errorInfo);
        }
    };

    const handleCancel = () => {
        props.onFinish();
    };

    return (
        <>
            <Modal
                title={t("my-profile.email-modification.change")}
                open={true}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t("form.confirm")}
                cancelText={t("form.cancel")}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="changeEmail"
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label={t("my-profile.email-modification.new-email")}
                        name="email"
                        rules={[
                            { required: true, message: t("my-profile.email-modification.new-email-placeholder"), type: 'email' },
                        ]}
                    >
                        <Input placeholder={t("my-profile.email-modification.new-email-placeholder")} />
                    </Form.Item>
                    <Form.Item
                        label={t("my-profile.email-modification.confirm-new-email")}
                        name="confirmEmail"
                        dependencies={['email']}
                        rules={[
                            { required: true, message: t("my-profile.email-modification.confirm-new-email-placeholder") },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('email') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t("my-profile.email-modification.match-new-email")));
                                },
                            })
                        ]}
                    >
                        <Input placeholder={t("my-profile.email-modification.confirm-new-email-placeholder")} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default EmailModal;
