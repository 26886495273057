import { Flex, Typography } from "antd";
import PetAvatar from "../../pets/PetAvatar";
import { useTranslation } from "react-i18next";

interface Pet {
    name: string,
    species: string,
    breed: string,
    id: string,
    avatar: string
}

function DisplayPet({ pet }: { pet: Pet }) {
    const {t} = useTranslation();

    return (
        <Flex align="center" gap={"small"}>
            <PetAvatar species={pet.species} iconSize={{width: "50px", height: "50px"}} url={pet.avatar} />
            <Flex align="left" justify="space-evenly" vertical>
                <Typography.Text><b>{t("family.pets.name")}:</b> {pet.name}</Typography.Text>
                <Typography.Text><b>{t("family.pets.breed")}:</b> {pet.breed}</Typography.Text>
            </Flex>
        </Flex>
    )
}

export default DisplayPet;