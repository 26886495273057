import { Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";

interface Member {
    firstname: string,
    lastname: string,
    email: string
}

function DisplayMember({ member }: {member: Member}) {
    const {t} = useTranslation();

    return (
        <Flex align="start" vertical>
            <Typography.Text><b>{t("family.members.firstname")}:</b> {member.firstname}</Typography.Text>
            <Typography.Text><b>{t("family.members.lastname")}:</b> {member.lastname}</Typography.Text>
            <Typography.Text><b>{t("family.members.email")}:</b> {member.email}</Typography.Text>
        </Flex>
    );
}

export default DisplayMember;