import { Flex, Input, message, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { sendFeedback } from "../../request/feedback";
import { useState } from "react";
import ImagesUploader from "./ImagesUploader";
import { useAuth0 } from "@auth0/auth0-react";

function FeedbackModal(props: {open: boolean, setOpen: (open: boolean) => void}) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [feedback, setFeedback] = useState("");
    const [fileList, setFileList] = useState<any[]>([]);

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    async function handleFeedback() {
        sendFeedback(await getAccessToken(), feedback, fileList);
        message.success(t('feedback.success'));
        setFeedback("");
        setFileList([]);
        props.setOpen(false);
    }

    return (
        <Modal
            title={t('feedback.give-us-feedback')}
            open={props.open}
            onOk={() => handleFeedback()}
            onCancel={() => props.setOpen(false)}
            okText={t('form.send')}
            cancelText={t('form.cancel')}
        >
            <Input.TextArea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder={t('feedback.feedback-placeholder')}
                autoSize={{ minRows: 6, maxRows: 12 }}
            />
            <ImagesUploader style={{marginTop: "10px"}} setFileList={setFileList} fileList={fileList}/>
            <Flex style={{ marginTop: '10px' }} vertical>
                <Typography.Title level={4}>{t('feedback.feedback-tips.title')}</Typography.Title>
                <ul style={{ marginLeft: '20px' }}>
                    <li>{t('feedback.feedback-tips.not-working')}</li>
                    <li>{t('feedback.feedback-tips.ideas')}</li>
                    <li>{t('feedback.feedback-tips.missing')}</li>
                    <li>{t('feedback.feedback-tips.positive-negative')}</li>
                    <li>{t('feedback.feedback-tips.provide-examples')}</li>
                </ul>
            </Flex>
        </Modal>
    );
}

export default FeedbackModal;