import React, { useEffect, useState } from 'react';
import { getSpecificUser } from '../../request/user/user';
import ProfileDisplay from '../../component/profile/ProfileDisplay';
import { useAuth0 } from '@auth0/auth0-react';

type UserDetails = {
  firstname: string,
  username: string,
  email: string
}

function Profile() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [user, setUser] = useState<UserDetails>();

  const getAccessToken = async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "openid profile email",
        },
      });
      return accessToken;
    }
    return "";
  }

  // TODO: maybe relocate this server call
  const getUser = async () => {
    const result = await getSpecificUser(await getAccessToken());

    setUser(result);
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
      <div className="Profile">
        <ProfileDisplay user={user}/>
      </div>
  );
}

export default Profile;
