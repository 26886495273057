import React, { PropsWithChildren, useState } from 'react';

import { Button, Flex, Modal, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
    path: string
}

function LeaveConfirm(props: PropsWithChildren<Props>) {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);


    const ModalTitle = () => {
        return (
            <Flex style={{ flexDirection: 'row' }} gap={10}>
                <ExclamationCircleFilled style={{ color: 'rgb(253, 179, 0)', fontSize: '22px' }}/>
                <Typography.Text style={{ fontSize: '16px' }}>{t('confirm.leave')}</Typography.Text>
            </Flex>
        );
    }

    return (
    <>
        <Flex onClick={() => setOpen(true)}>
            {props.children}
        </Flex>
        <Modal
            closeIcon={null}
            open={open}
            height={138}
            width={416}
            title={<ModalTitle/>}
            okText={t('form.go-to')}
            okType='danger'
            cancelText={t('form.cancel')}
            onCancel={() => setOpen(false)}
            onOk={() => navigate(props.path)}
            destroyOnClose
        >
            <br/>
        </Modal>
    </>
  );
}

export default LeaveConfirm;
