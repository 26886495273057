import React, { useEffect, useState } from "react"
import { Button, Card, Divider, Drawer, Flex, Form, Input, InputNumber, Radio, Segmented, Select, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { FaBookMedical } from "react-icons/fa";

import '../healthRecord.css'
import { getUserSelectedPet } from "../../../request/user/pet";
import { formatAge } from "../../pets/PetDisplay";
import { customTheme } from "../../../config/theme";
import { useTranslation } from "react-i18next";
import PetAvatar, { assignPetAvatar } from "../../pets/PetAvatar";
import { ReactComponent as PetReport } from "../../../assets/icons/healthRecord/PetReport.svg";
import { useAuth0 } from "@auth0/auth0-react";

function HealthCardDrawer(props: any) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);

    const [petInfo, setPetInfo] = useState<any>();

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const getPetInfo = async () => {
        const response = await assignPetAvatar(await getUserSelectedPet(await getAccessToken()), "_id", "avatar", false);

        setPetInfo(response);
    }

    useEffect(() => {
        getPetInfo();
    }, []);

    return (
        <Drawer
            className="health-record-drawer"
            onClose={() => handleClose()}
            open={open}
            width={650}
            closeIcon={<LeftOutlined />}
            title={
                <>
                    <Flex style={{ width: '30px'}}>
                        <PetReport/>
                    </Flex>
                    {t("health-records-page.health-card")}
                </>
            }
        >
            {petInfo &&
                <Flex style={{ flexDirection: 'column' }}>
                    <Flex style={{ flexDirection: 'column' }} gap={16}>
                        <Flex justify='space-between' style={{ flexDirection: 'row', marginTop: '20px' }}>
                            <Flex style={{ flexGrow: 1, flexDirection: 'column' }} gap={18}>
                                <Typography.Text style={{ fontSize: '17px' }}>{t("pet-profile.chip-id")}: {petInfo.pet_id_number}</Typography.Text>
                                <Typography.Text style={{ fontSize: '17px' }}>{t("pet-profile.breed")}: {petInfo.breed}</Typography.Text>
                                <Typography.Text style={{ fontSize: '17px' }}>{t("pet-profile.gender")}: {petInfo.gender}</Typography.Text>
                            </Flex>
                            <Flex className="health-card-drawer-pet-picture" style={{ flexDirection: 'column', alignItems: 'center', marginRight: '90px' }}>
                                <Flex
                                    justify='center'
                                    style={{
                                        border: `1px solid ${customTheme.colorPrimary}`, borderRadius: '100px', borderStyle: 'dashed',
                                        height: '100px', width: '100px', alignItems: 'center'
                                    }}
                                >
                                    <PetAvatar
                                        species={petInfo.species}
                                        url={petInfo.avatar}
                                        iconSize={{width: "60px", height: "60px"}}
                                        imageSize={{width: "100%", height: "100%"}}
                                    />
                                </Flex>
                                <Typography.Text style={{ fontWeight: 'bold' }}>{petInfo.name}</Typography.Text>
                            </Flex>
                        </Flex>
                        <Flex style={{ flexDirection: 'column' }} gap={18}>
                            <Typography.Text style={{ fontSize: '17px' }}>{formatAge(petInfo.birth_date, t)}</Typography.Text>
                            <Typography.Text style={{ fontSize: '17px' }}>{t("pet-profile.sprayed")}: {petInfo.neutered == true ? t("pet-profile.sprayed") : t("pet-profile.unsterilized")}</Typography.Text>
                        </Flex>
                    </Flex>
                    <Divider/>
                    <Input.TextArea
                        style={{ backgroundColor: 'rgba(197, 194, 194, 0.5)', borderRadius: '5px', color: 'black', cursor: 'default', marginTop: '5px', marginBottom: '10px' }}
                        autoSize={{ minRows: 4 }}
                        disabled
                        value={petInfo.medical_history}
                    />
                </Flex>
            }
        </Drawer>
    );
}

export { HealthCardDrawer }
