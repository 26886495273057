import React, { useState } from "react"
import { Form, Input, Modal, Select } from "antd";
import dayjs from 'dayjs';

import { TypeDataAppointment } from './typeData';
import { createAppointment } from "../../../request/events";
import { dateFormat } from "../../../config/format";
import CustomDatePicker from "../../customPicker/CustomDatePicker";
import CustomTimePicker from "../../customPicker/CustomTimePicker";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

const { TextArea } = Input;

function Appointment(props: any) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation();

    const [form] = Form.useForm();
    const [pet, setPet] = useState<any>();

    const [type, setType] = useState("");
    const [otherType, setOtherType] = useState("");

    const [date, setDate] = useState("");
    const [time, setTime] = useState("");

    const [motive, setMotive] = useState("");
    const [notes, setNotes] = useState("");

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    const handleSubmit = async (event: any) => {
        const formattedDate = dayjs(date).format(`${dateFormat}`);
        const formattedTime = dayjs(time).format('HH:mm');

        const formattedDateTime = `${formattedDate} ${formattedTime}`;
        let newType = type;

        if (type === "Autre") {
            newType = otherType;
        }

        try {
            await createAppointment(await getAccessToken(), { pet_id: pet._id }, newType, formattedDateTime, motive, notes)
        } catch (error: any) {
            console.log(error.response.status)
        }
        props.onClose(true);
    }

    return (
        <Modal
            open={true}
            height={'auto'}
            title={t("appointment-creation.add-appointment")}
            okText={t("form.add")}
            cancelText={t("form.cancel")}
            onCancel={() => props.onClose(false)}
            onOk={() => {
                    form.submit();
                }
            }
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    layout="vertical"
                    style={{ maxWidth: 600 }}
                    form={form}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={handleSubmit}
                    variant='filled'
                    clearOnDestroy
                >
                    {dom}
                </Form>
            )}
        >
            <Form.Item
                label={t("appointment-creation.pet")}
                name="pet"
                rules={[{ required: true, message: t("appointment-creation.select-pet") }]}
            >
                <Select placeholder={t("appointment-creation.select-pet")} onSelect={(e, object) => setPet(props.pets[object.key])}>
                    {props.pets[0] !== ""  && props.pets.map((val: any, key: any) => {
                        return (
                            <Select.Option key={key} value={val.name}>{val.name}</Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label={t("appointment-creation.type")}
                name="type"
                rules={[{ required: true, message: t("appointment-creation.select-type") }]}
            >
                <Select placeholder={t("appointment-creation.select-type")} onSelect={(e) => setType(e)}>
                    {TypeDataAppointment(t).map((val: any, key: any) => {
                        return (
                            <Select.Option key={key} value={val.value}>{val.label}</Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            {type === "Autre" ? (
                <Form.Item
                    label={t("appointment-creation.other-type")}
                    name="newType"
                    rules={[{ required: true, message: t("appointment-creation.other-type-placeholder") }]}
                >
                    <Input placeholder={t("appointment-creation.other-type-placeholder")} onChange={(e) => setOtherType(e.target.value)}/>
                </Form.Item>
            ) : (
                null
            )}
            <Form.Item
                label={t("appointment-creation.reason")}
                name="motive"
            >
                <TextArea rows={1} placeholder={t("appointment-creation.reason-placeholder")} onChange={(e) => setMotive(e.target.value)} maxLength={300}/>
            </Form.Item>
            <Form.Item
                name="date_time"
                style={{ margin: 0 }}
            >
                <Form.Item
                    label={t("appointment-creation.date")}
                    name="date"
                    style={{ display: 'inline-block', width: '48%', marginRight: '4%'}}
                    rules={[{ required: true, message: t("appointment-creation.date-placeholder") }]}
                >
                    <CustomDatePicker placeholder={t("appointment-creation.date-placeholder")} style={{ width: '100%' }} onChange={(e: any) => setDate(e as any)} format={`${dateFormat}`}/>
                </Form.Item>
                <Form.Item
                    label={t("appointment-creation.time")}
                    name="time"
                    style={{ display: 'inline-block', width: '48%' }}
                    rules={[{ required: true, message: t("appointment-creation.time-placeholder") }]}
                >
                    <CustomTimePicker
                        inputReadOnly={true}
                        placement={"topRight"}
                        placeholder={t("appointment-creation.time-placeholder")}
                        style={{ width: '100%' }}
                        onChange={(e: any) => setTime(e as any)}
                        format={'HH:mm'}
                    />
                </Form.Item>
            </Form.Item>
            <Form.Item
                label={t("appointment-creation.additional-information")}
                name="complementary"
            >
                <TextArea rows={4} placeholder={t("appointment-creation.additional-information")} onChange={(e) => setNotes(e.target.value)} maxLength={300}/>
            </Form.Item>
        </Modal>
    );
}

export {Appointment}