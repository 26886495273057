import React, { useEffect, useState } from 'react';
import { Button, ConfigProvider, Flex, Image, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';

import './imageUploader.css'
import { NO_IMAGE } from '../../config/define';

export interface Image {
  size: number,
  mimeType: string,
  file: any,
}

export interface ImageUploader {
  setImage: React.Dispatch<React.SetStateAction<Image>>,
  defaultImage?: string,
  disabled?: boolean,
  cancel?: boolean,
  showPreview?: boolean,
  uploaderIcon?: any,
  crop?: boolean
}

export const defaultImage = {
  size: 0,
  mimeType: "",
  file: "" as any,
};

function ImageUploader(options: ImageUploader) {
  const [thumbUrl, setThumbUrl] = useState(NO_IMAGE);

  const [reRender, setReRender] = useState(new Date());

  const handleImageUpload = (file: any, fileList: any) => {
    const fileBuffer = file;
    const reader = new FileReader();

    if (fileBuffer) {
      reader.readAsDataURL(fileBuffer);
    }
    setTimeout(() => {
      setReRender(new Date());
      options.setImage({
        size: file.size || 0,
        mimeType: file.type || "image/png",
        file: file,
      });
      setThumbUrl(reader.result as string);
    }, 150);
  }

  useEffect(() => {
    setThumbUrl(options.defaultImage || NO_IMAGE);
  }, [options.cancel])

  useEffect(() => {
    if (thumbUrl == NO_IMAGE) {
      setThumbUrl(options.defaultImage || NO_IMAGE);
    }
  }, [reRender])

  return (
    <Flex>
        {options.showPreview &&
          <Image
              className='image-uploader-preview'
              style={{ borderRadius: '150px', width: '102px', height: '102px', position: 'absolute', zIndex: 0 }}
              preview={false}
              src={thumbUrl}
          />
        }
        <Flex justify='center' style={{ zIndex: 1, position: 'relative' }}>
          {options.crop
            ? <ImgCrop rotationSlider cropShape='round' showGrid modalTitle={`Modifier l'image`}>
                  <Upload
                      {...!options.uploaderIcon ? { style: { width: '100px', height: '100px', position: 'absolute' }, listType: "picture-circle" } : null }
                      accept='.png,.jpeg,.jpg'
                      disabled={options.disabled || false}
                      beforeUpload={(file, fileList) => {
                        handleImageUpload(file, fileList)
                        return false;
                      }}
                  >
                    {options.uploaderIcon
                      ? <Button icon={options.uploaderIcon} style={{ border: 'none', boxShadow: 'none', overflow: 'hidden', height: '100%' }}></Button>
                      : ' '
                    }
                  </Upload>
              </ImgCrop>
            : <Upload
                {...!options.uploaderIcon ? { style: { width: '100px', height: '100px', position: 'absolute' }, listType: "picture-circle" } : null }
                accept='.png,.jpeg'
                disabled={options.disabled || false}
                beforeUpload={(file, fileList) => {
                  handleImageUpload(file, fileList)
                  return false;
                }}
              >
                {options.uploaderIcon
                  ? <Button
                      disabled={options.disabled || false}
                      icon={options.uploaderIcon}
                      style={{ border: 'none', boxShadow: 'none', overflow: 'hidden', backgroundColor: 'transparent', height: '100%' }}
                    ></Button>
                  : ' '
                }
              </Upload>
          }
        </Flex>
    </Flex>
  );
};

export default ImageUploader;
