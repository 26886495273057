import { Flex, Input, message, Modal, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function FamilySettingsModal(props: any) {
    const [name, setName] = useState<string>(props.family.name);
    const [owner, setOwner] = useState<string>(props.family.owner);
    const {t} = useTranslation();

    function handleOk() {
        const nameToSend = (name === props.family.name) ? null : name;
        const ownerToSend = (owner === props.family.owner) ? null : owner;

        if (ownerToSend) {
            Modal.confirm({
                title: t("family.settings.owner-confirmation"),
                content: t("family.settings.owner-confirmation-description"),
                onOk: () => {
                    props.onOk({ name: nameToSend, owner: ownerToSend });
                },
                onCancel: () => {
                    message.info(t("family.settings.owner-cancel"));
                },
                okText: t("form.confirm"),
                cancelText: t("form.cancel")
            });
        } else {
            props.onOk({ name: nameToSend, owner: ownerToSend });
        }
    }

    return (
        <Modal
            title={t("family.settings.title")}
            open={props.open}
            onCancel={props.onCancel}
            onOk={handleOk}
            okButtonProps={{ disabled: name === props.family.name && owner === props.family.owner }}
            okText={t("form.confirm")}
            cancelText={t("form.cancel")}
        >
            <Flex vertical>
                <Typography.Text>{t("family.settings.change-name")}</Typography.Text>
                <Input placeholder={t("family.settings.name-placeholder")} onChange={(e) => setName(e.target.value)} value={name}/>
                <Typography.Text>{t("family.settings.change-owner")}</Typography.Text>
                <Select
                    placeholder={t("family.settings.owner-placeholder")}
                    onChange={(value) => setOwner(value)}
                    style={{ width: '100%' }}
                    value={owner}
                >
                    {props.family.members.map((member: any) => (
                        <Select.Option key={member.id} value={member.id}>{member.firstname} {member.lastname}</Select.Option>
                    ))}
                </Select>
            </Flex>
        </Modal>
    )
}

export default FamilySettingsModal;
