import React, { useEffect, useState } from "react"
import { Button, Card, Divider, Drawer, Flex, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { EventDrawerAppointment } from "../../calendar/drawer/AppointmentDrawer";
import { customTheme } from "../../../config/theme";
import { isDateToday } from "../../weight/drawer/WeightDrawer";
import CalendarModal from "../../calendar/modal/CalendarModal";
import { ReactComponent as Veterinarian } from "../../../assets/icons/healthRecord/Veterinarian.svg";
import { HealthDrawer } from "../HealthSection";
import PetAvatar from "../../pets/PetAvatar";

function VetHistoryDrawer(props: HealthDrawer) {
    const {t} = useTranslation();

    const [open, setOpen] = useState(true);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [isModalVisible, setModalVisibility] = useState(false);

    const [petEvents, setPetEvents] = useState<any>();
    const [drawerEvent, setDrawerEvent] = useState<any>();

    const handleClose = () => {
        setOpen(false)
        props.onClose();
    }

    const onDrawerClose = () => {
        setTimeout(() => {
            setOpenDrawer(false);
        }, 250);
    }

    const handleModalSubmit = async (submit: boolean) => {
        setModalVisibility(false);
        if (submit && props.renew) {
            props.renew()
        }
    }

    useEffect(() => {
        setPetEvents(props.events);
    })

    return (
        <>
            {isModalVisible && <CalendarModal selectedEvent={"appointment"} onSubmit={handleModalSubmit}/>}
            {openDrawer && <EventDrawerAppointment onClose={onDrawerClose} event={drawerEvent} renew={props.renew} events={props.events}/>}
            <Drawer
                className="health-record-drawer"
                onClose={() => handleClose()}
                open={open}
                width={650}
                closeIcon={<LeftOutlined />}
                title={
                    <>
                      <Flex style={{ width: '30px'}}>
                        <Veterinarian/>
                      </Flex>
                      {t("health-records-page.veterinary-history")}
                    </>
                }
                footer={
                    <Flex justify="end">
                        <Button type="primary" onClick={() => setModalVisibility(true)}>
                            {t("appointment-creation.add-appointment")}
                        </Button>
                    </Flex>
                }
            >
                <Flex style={{ flexDirection: 'column' }} gap={'13px'}>
                    {petEvents && petEvents.map((event: any, key: number) => (
                        <>
                            {(event.type === 'A' && event.name === 'veterinarian') &&
                                <Card
                                    key={key}
                                    style={{
                                        height: 'auto',
                                        minWidth: '100px',
                                        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                                    }}
                                    hoverable
                                    onClick={() => {
                                        setDrawerEvent(event);
                                        setOpenDrawer(true);
                                    }}
                                >
                                    <Flex
                                        style={{ alignItems: 'center', cursor: 'pointer', width: '100%' }}
                                        gap={10}
                                    >
                                        <PetAvatar species={event.pet.species} url={event.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                                        {' '}
                                        <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                                        <Typography.Text className='drawer-weight-text-display' style={{ fontSize: '17px' }}>
                                            {(event.date).replaceAll('/', '-')}
                                            {isDateToday(event.date.split()[0]) ? ` (AUJOURD'HUI)` : '' } - {t('appointment-creation.appointment-type.veterinarian')}
                                        </Typography.Text>
                                    </Flex>
                                </Card>
                            }
                        </>
                    ))}
                </Flex>
            </Drawer>
        </>
    );
}

export { VetHistoryDrawer }
