import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './sideBar.css'
import { SideBarData } from './sideBarData'
import { Badge, ConfigProvider, Flex, Menu } from 'antd';
import { useGlobalState } from '../../state/globalState';
import { customTheme } from '../../config/theme';
import FeedbackButton from '../utils/FeedbackButton';
import FeedbackModal from '../utils/FeedbackModal';
import { MessageFilled } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { getSpecificUser } from '../../request/user/user';

type PropsSideBar = {
    mode: "inline" | "horizontal";
    display: string;
}

function SideBar(props: PropsSideBar) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const [numberOfPets, setNumberOfPets] = useGlobalState('numberOfPets');
    const [footerVisibility, setFooterVisibility] = useGlobalState('footerVisibility');
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [selectedKey, setSelectedKey] = useState("");

    const [userInformation, setUserInformation] = useState({firstname: " ", lastname: " "});

    const pathToExclude: string[] = ["/", "/home", "/register", "/login"];

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
      }

    const getUser = async () => {
        const result = await getSpecificUser(await getAccessToken());

        if (result) {
            setUserInformation({firstname: result?.firstname, lastname: result?.lastname});
        }
    }

    const isDisabled = (key: string) => {
        return !(numberOfPets >= 1 || ["/profile", "/application"].includes(key))
    }

    useEffect(() => {
        getUser();
    }, []);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        colorPrimary: customTheme.colorPrimary,
                        darkItemColor: customTheme.textColor,
                        darkItemBg: customTheme.colorSecondary,
                        darkItemHoverBg: customTheme.colorPrimaryHover,
                        darkItemHoverColor: 'black',
                        horizontalItemHoverBg: customTheme.colorPrimaryHover,
                        darkItemDisabledColor: 'rgb(120, 119, 117, 0.4)'
                    },
                    Badge: {
                        statusSize: 8
                    }
                }
            }}
        >
            {!pathToExclude.includes(window.location.pathname) ? (
                <>
                    <Menu
                        theme='dark'
                        mode={props.mode}
                        defaultSelectedKeys={[window.location.pathname]}
                        {...props.display == "sider"
                            ? { style : { fontSize: '18px', width: '250px', position: 'fixed', height: '100vh', zIndex: 999, borderRight: '1px solid rgb(236, 236, 236)' } }
                            : { style : { fontSize: '18px', zIndex: 999 } }
                        }
                        className={`sidebar-menu ${props.display}`}
                        selectedKeys={[window.location.pathname]}
                    >
                        {SideBarData().map((value: any, key: number) => (
                            <Menu.Item
                                className='sidebar-menu-item'
                                disabled={isDisabled(value.key)}
                                key={value.key}
                                icon={
                                    value.icon(
                                        (value.key == window.location.pathname && selectedKey != "/feedback")
                                            ? 'white' : isDisabled(value.key) ? 'rgb(120, 119, 117, 0.4)' : customTheme.colorPrimary
                                            , footerVisibility ? 1.5 : 1
                                            , props.display == "sider"
                                            )
                                        }
                                onClick={() => {
                                    setSelectedKey(value.key);
                                    if (!isDisabled(value.key)) {
                                        navigate(value.key)
                                    }
                                }}
                            >
                                <Flex justify='space-between'>
                                    {value.label}
                                    {value.key == "/profile" && (userInformation.firstname == "" || userInformation.lastname == "") &&
                                        <Badge status={"error"}/>
                                    }
                                </Flex>
                            </Menu.Item>
                        ))}
                        {props.mode != "inline" ? (
                            <Menu.Item
                                className='sidebar-menu-item'
                                key="/feedback"
                                icon={
                                    <Flex justify='center' style={{ alignItems: 'center', height: '20px', width: '20px', transform: `scale(1.5)` }}>
                                        <MessageFilled style={{ color: selectedKey === "/feedback" ? "white" : customTheme.colorPrimary} }/>
                                    </Flex>
                                }
                                onClick={() => {
                                    setSelectedKey("/feedback");
                                    setFeedbackOpen(true);
                                }}
                            />
                        ) : null}
                    </Menu>
                    {props.mode == "inline" ? <FeedbackButton onSidebar/> : <FeedbackModal open={feedbackOpen} setOpen={setFeedbackOpen}/>}
                </>
            ) : (
                null
            )}
        </ConfigProvider>
    );
}

export default SideBar;
