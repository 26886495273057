import React, { useEffect, useState } from 'react';
import { Flex, Select } from 'antd';

import './selectPet.css'
import { useGlobalState } from '../../state/globalState';
import { getPetsFromUser } from '../../request/pets';
import { getUserSelectedPet, createSelectedPetCookies } from '../../request/user/pet';
import PetAvatar, { assignPetAvatar, savePetAvatars } from '../pets/PetAvatar';
import { useAuth0 } from '@auth0/auth0-react';
import { TeamOutlined } from '@ant-design/icons';

interface FormattedPet {
  label: string,
  value: string,
  species: string,
  avatar: string,
  type?: string
}

function SelectPet(props: any) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [reRender, setReRender] = useGlobalState('reRender');
  const [reRenderSelectPet, setReRenderSelectPet] = useGlobalState('reRenderSelectPet');
  const [userPets, setUserPets] = useGlobalState('userPets');
  const [selectedPetName, setSelectedPetName] = useGlobalState('selectedPetName');
  const [numberOfPets, setNumberOfPets] = useGlobalState('numberOfPets');

  const [formatedPetList, setFormatedPetList] = useState<FormattedPet[]>([])
  const [selectedPet, setSelectedPet] = useState("");

  const getAccessToken = async () => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
          scope: "openid profile email",
        },
      });
      return accessToken;
    }
    return "";
  }

  const formatPetList = async (userPet: any) => {
    let newPetList: FormattedPet[] = []

    if (userPet && userPet[0] !== "") {
      for (let i = 0; i < userPet.length; i++) {
        newPetList.push({label: userPet[i].name, value: userPet[i]._id, species: userPet[i].species, avatar: userPet[i].avatar, type: userPet[i].type});
      }
    }
    return newPetList;
  }

  const changeUserSelectedPet = async (value: string, object: any) => {
    if (props.changeCookies) {
      await createSelectedPetCookies(await getAccessToken(), value);

      setSelectedPetName(object?.label || "");
      setSelectedPet(value)
    } else if (!props.changeCookies) {
      setSelectedPet(object.label);
      props.onChange({name: object?.label, species: object?.species, pet_id: object?.value});
      return;
    }
    if (props.onChange) {
      props.onChange();
    }
  }


  const initSelectPet = async () => {
    try {
      const result = await getPetsFromUser(await getAccessToken());

      if (props.changeCookies) {
        await savePetAvatars(result.avatars, false);
        if (reRenderSelectPet != 'no-re-render') {
          setReRender(new Date());
        }
      }
      const pets = await assignPetAvatar(result.pets, "_id", "avatar", true);
      if (pets) {
        setNumberOfPets(pets.length);
      }
      setUserPets(pets);
      setFormatedPetList(await formatPetList(pets));
      if (props.pet) {
        setSelectedPet(props.pet);
      } else {
        const response = await getUserSelectedPet(await getAccessToken());
        if (pets) {
          if (pets.length >= 1 && ((!response || response.status === 401 && response.data.msg === 'No pet selected') ||
            !pets.some((obj: any) => obj._id == selectedPet))) {
            changeUserSelectedPet(pets[0]._id, {label: pets[0]?.name});
          } else if (pets.length == 0 && !pets.some((obj: any) => obj._id == selectedPet)) {
            changeUserSelectedPet("no-pet-available", {label: "no-pet-available"});
          } else {
            setSelectedPetName(response.name);
            setSelectedPet(response._id);
          }
        }
      }
    } catch (err: any) {
      console.log(err?.message);
    }
  }

  useEffect(() => {
    initSelectPet()
  }, [props.pet, reRenderSelectPet, isAuthenticated]);

  return (
    <>
      {formatedPetList !== null && numberOfPets >= 1 ? (
        <>
          <Select
            {...selectedPet != "no-pet-available" && {value : selectedPet}}
            style={{ width: props.width || 'auto', minWidth: '150px', height: props.height || '' }}
            placeholder='Sélectionner votre animal'
            className={props.className || ''}
            disabled={props.disabled || false}
            onChange={(value) => {
              const petObject = formatedPetList.find(pet => pet.value == value)

              changeUserSelectedPet(value, petObject)
            }}
          >
            {formatedPetList.map((pet: FormattedPet, key: number) =>  (
              <Select.Option key={key} value={pet.value}>
                <Flex style={{ flexDirection: 'row', alignItems: 'center' }} gap={10}>
                  <PetAvatar species={pet.species} url={pet.avatar} iconSize={{width: "30px", height: '30px'}} imageSize={{width: "30px", height: '30px'}}/>
                  {pet.label}
                  {(pet.type == "family" || pet.type == "shared") && <TeamOutlined />}
                </Flex>
              </Select.Option>
            ))}
          </Select>
        </>
      ) : (
        null
      )}
    </>
  );
}

export default SelectPet;

