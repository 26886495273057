import React, { useEffect } from 'react';

import { useGlobalState } from '../../../state/globalState';
import { getPetsFromUser } from '../../../request/pets';
import { Treatment } from './TreatmentModal';
import { Appointment } from './AppointmentModal';
import { useAuth0 } from '@auth0/auth0-react';

type PropsCalendarModal = {
    selectedEvent: "treatment-reminder" | "appointment",
    onSubmit: (submit: boolean) => void
}

function CalendarModal(props: PropsCalendarModal) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [userPet, setUserPet] = useGlobalState('userPets');

    const closeModal = (submit: boolean) => props.onSubmit(submit);

    const getPetList = async () => {
        let accessToken = ""
        if (isAuthenticated) {
            accessToken = await getAccessTokenSilently({
                authorizationParams: {
                  audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
                  scope: "read:current_user",
                },
            });
        }
        setUserPet(await getPetsFromUser(accessToken));
    }

    useEffect(() => {
        if (userPet[0] === "") {
            getPetList();
        }
    }, []);

  return (
    <div className="CalendarModal">
        {props.selectedEvent === "treatment-reminder" ? (
            <Treatment pets={userPet} onClose={closeModal}/>
        ) : (
            <Appointment pets={userPet} onClose={closeModal}/>
        )}
    </div>
  );
}

export default CalendarModal;
