import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getAllActivityFromPet = async (accessToken: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/all`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(`there was an error: ${error}`);
      return [""];
    }
}

const getTodayActiviyFromPet = async (accessToken: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activity/today`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
    return response.data;
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
    return null;
  }
}

const deleteSingleActivity = async (accessToken: string, id: string, index: number) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/activity/${id}/${index}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

const deleteActivity = async (accessToken: string, id: string) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/activity/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true
      }
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

const createActivity = async (accessToken: string, activity: {created_at: string, duration: string, id: number}[], date: string) => {
  try {
      const data = JSON.stringify({activity, createdAt: date}, null, 1)
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/activity `,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
      throw error;
  }
}

const updateTodayActivity = async (accessToken: string, activity: {created_at: string, duration: string, id: number}[], date: string) => {
  try {
      const data = JSON.stringify({activity, createdAt: date}, null, 1)
      const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/activity/today`,
          data,
          {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
              },
              withCredentials: true
          },
      );
  } catch (error: any) {
      throw error;
  }
}

const updateActivity = async (accessToken: string, activity: {created_at: string, duration: string, type: string, id: number}, id: string) => {
  try {
    const data = JSON.stringify({activity, id}, null, 1)
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/activity `,
      data,
      {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          withCredentials: true
      },
    );
  } catch (error: any) {
    console.log(`there was an error: ${error}`);
  }
}

export {
  getTodayActiviyFromPet,
  getAllActivityFromPet,
  createActivity,
  deleteActivity,
  deleteSingleActivity,
  updateTodayActivity,
  updateActivity
}