import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const getSpecificUser = async (accessToken: string) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response.data;
    } catch (error: any) {
        return null;
    }
}

const updateUser = async (accessToken: string, firstname: string, lastname: string, phoneNumber: string) => {
    try {
        const data = JSON.stringify({firstname, lastname, phoneNumber}, null, 1)
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/user`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}

const updateUserEmail = async (accessToken: string, email: string, user_id: string) => {
    try {
        const authToken = await axios.post(`https://${process.env.REACT_APP_AUTH_DOMAIN}/oauth/token`,
            {
                grant_type: 'client_credentials',
                client_id: process.env.REACT_APP_AUTH_CLIENT_ID as string,
                client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET as string,
                audience: `https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/`,
            },
            {
                headers: {
                    'content-type': 'application/json'
                },
            }
        )
        const authResponse = await axios.patch(`https://${process.env.REACT_APP_AUTH_DOMAIN}/api/v2/users/${user_id}`,
            JSON.stringify({email, name: email}, null, 1),
            {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${authToken.data.access_token}`
                },
            }
        )
        if (authResponse.status == 200) {
            const data = JSON.stringify({email}, null, 1)
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/user/email`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    withCredentials: true
                },
            );
            return response;
        } else {
            return {}
        }
    } catch (error: any) {
        return error;
    }
}

const updateUserPassword = async (accessToken: string, password: string, old_password: string) => {
    try {
        const data = {
            password: password,
            oldPassword: old_password
        }
        const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/user/password`,
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                withCredentials: true
            },
        );
        return response;
    } catch (error: any) {
        return error;
    }
}


export {
    getSpecificUser,
    updateUser,
    updateUserEmail,
    updateUserPassword
}
