import React, { useState } from "react"
import { Card, Divider, Flex, Typography, message } from "antd";
import _ from 'lodash';
import { GoTrash } from "react-icons/go";
import { IoIosLink } from "react-icons/io";

import DeleteConfirm from "../confirm/DeleteConfirm";
import { customTheme } from "../../config/theme";
import PetAvatar from "../pets/PetAvatar";
import { getTranslatedEventType } from "./CalendarDisplay";
import { useTranslation } from "react-i18next";
import { removeLink } from "../../request/events";
import { useAuth0 } from "@auth0/auth0-react";

interface LinkedEvent {
    events: any;
    event: any;
    onFinish?: () => void;
    openEvent:(events: any) => void
}

function findLinkedAppointment(events: any, linkedEventId: string) {
    let linkedAppointment: any = null;

    if (events) {
        linkedAppointment = events.find((event: any) => linkedEventId == event._id);
    }
    return linkedAppointment
}

function LinkedAppointmentDisplay(props: LinkedEvent) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const {t} = useTranslation()

    const linkedAppointment = findLinkedAppointment(props.events, props.event.linked_event.event_id)

    const getAccessToken = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
              scope: "openid profile email",
            },
          });
          return accessToken;
        }
        return "";
    }

    if (!linkedAppointment) {
        return (
            null
        );
    }

    const handleSubmit = async () => {
        try {
            await removeLink(await getAccessToken(), props.event._id, props.event.linked_event.event_id)
            if (props.onFinish) {
                props.onFinish()
            }
        } catch(err) {
            message.error(t('treatment-reminder-drawer.error'))
        }
    }

    return (
        <Flex style={{ flexDirection: 'column' }} gap={10}>
            <Card
                style={{
                    height: 'auto',
                    cursor: 'pointer',
                    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
                }}
                hoverable
                onClick={() => {
                    if (props.openEvent) {
                        props.openEvent(linkedAppointment)
                    }
                }}
            >
                <Flex justify="space-between" style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Flex justify='start' style={{ flexDirection: 'row', alignItems: 'center' }} gap={1}>
                        <PetAvatar species={linkedAppointment.pet.species} url={linkedAppointment.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                        {' '}
                        <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                        <Typography.Text style={{ fontSize: '17px' }}>{(linkedAppointment.date).split(" ")[0]} - {getTranslatedEventType(linkedAppointment.name, t)}</Typography.Text>
                    </Flex>
                    <IoIosLink style={{ fontSize: '20px', color: customTheme.colorPrimary, position: 'absolute', right: '10px' }}/>
                </Flex>
            </Card>
            <Flex>
                <DeleteConfirm
                    delete={handleSubmit}
                    deleteText={t('confirm.delete.link-remove')}
                >
                    {t('treatment-reminder-drawer.remove-link')} <GoTrash/>
                </DeleteConfirm>
            </Flex>
        </Flex>
    );
}

function findLinkedTreatment(events: any, linkedEventIds: string[]) {
    let linkedTreatment: any[] = [];
    if (events) {
        linkedTreatment = events.filter((event: any) => linkedEventIds.includes(event._id))
    }
    return linkedTreatment
}

function LinkedTreatmentDisplay(props: LinkedEvent) {
    const {t} = useTranslation()
    const linkedTreatment = findLinkedTreatment(props.events, props.event.linked_event.event_id)

    if (linkedTreatment.length == 0) {
        return (
            null
        );
    }

    return (
        <>
            {linkedTreatment.map((event: any, key: number) => (
                <Card
                    key={key}
                    style={{
                        height: 'auto',
                        cursor: 'pointer',
                        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
                    }}
                    hoverable
                    onClick={() => {
                        if (props.openEvent) {
                            props.openEvent(event)
                        }
                    }}
                >
                    <Flex justify="space-between" style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Flex justify='start' style={{ flexDirection: 'row', alignItems: 'center' }} gap={1}>
                            <PetAvatar species={event.pet.species} url={event.pet.avatar} iconSize={{width: "30px", height: "30px"}}/>
                            {' '}
                            <Divider type='vertical' style={{ backgroundColor: customTheme.colorPrimary, height: '32px', width: '2px', border: 'none' }}></Divider>
                            <Typography.Text style={{ fontSize: '17px' }}>{(event.date).split(" ")[0]} - {getTranslatedEventType(event.name, t)}</Typography.Text>
                        </Flex>
                        <IoIosLink style={{ fontSize: '20px', color: customTheme.colorPrimary, position: 'absolute', right: '10px' }}/>
                    </Flex>
                </Card>
            ))
            }
        </>
    );
}


export { LinkedAppointmentDisplay, LinkedTreatmentDisplay }